import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {useState} from "react";
import {useHttp} from "../../hooks/http.hook";
import {beurl} from "../../constants/Constants";
import * as Yup from "yup";
import {emailSchema, yupErrorList} from "../../services/ValidationService";
import {Button} from "../../forms/Button";
import {AnimatePresence, motion} from "framer-motion";
import {OpacityAnimation} from "../TaskComp/TaskAnimations";
import {useNavigate} from "react-router-dom";
import {ErrorMessage} from "../SignUpComp";

export const PasswordResetComp = () => {

    /* Local constant */
    const request = useHttp();
    const url = beurl(window.location.host.split(':')[0]) + "api/login/passwordreset"

    /* Local states */
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState<{[key: string]: string}>({});

    const navigate = useNavigate();

    /* Web requests */
    const sendLink = () => {
        request(url, 'POST', JSON.stringify(email))
            .then(() => navigate("/login"))
            .catch((e) => {
                setErrorMessage(e.message);
                console.log(e.message);
            })
    }

    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            handleSubmit();
        }
        if (key === "Escape") {
            // onCancel();
        }
    }

    /* Validation */
    const schema = Yup.object().shape({
        email: emailSchema
    });

    const handleSubmit = () => {
        schema.validate({email: email}, {abortEarly: false})
            .then(() => {
                setErrors({});
                sendLink();
            })
            .catch((err) => {
                if (err instanceof Yup.ValidationError) {
                    setErrors(yupErrorList(err));
                }
            })
    }

    return (
        <div>
            <div className="center-all">
                <div className="login-plate mt-5">
                    <div className="heading1">Password restore</div>
                    <div>
                        Enter your registered e-mail to send password reset link.
                    </div>
                    <div className="mt-4">
                        <InputSelectField value={email} handleAction={setEmail}
                                          type={InputSelectType.input}
                                          label="E-mail"
                                          backgroundcolor="#fafafa"
                                          handleKeyAction={handleKeyUp}
                                          error={errors.hasOwnProperty("email")}
                        />
                    </div>
                    <AnimatePresence>
                        {errors.hasOwnProperty("email") &&
                            <ErrorMessage message={errors["email"]}/>
                        }
                    </AnimatePresence>
                    <div className="center-horizontally overflow-hidden mt-3" style={{height: "1.5rem"}}>
                        {errorMessage !== "" &&
                            <AnimatePresence>
                                <motion.div
                                    {...OpacityAnimation}
                                    className="error-text"
                                >
                                    {errorMessage}
                                </motion.div>
                            </AnimatePresence>
                        }
                    </div>
                    <div className="center-all mt-3 d-flex gap-2">
                        <Button className="but but-primary but-sm" style={{width: "70px"}} onClick={handleSubmit}>Send</Button>
                        <Button className="but but-warning but-sm" style={{width: "70px"}} onClick={() => navigate("/login")}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}