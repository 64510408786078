import {DataOptionsUnit, InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {Task} from "../../model/Task";
import {UserBasic} from "../../model/UserBasic";
import dayjs from "dayjs";
import {CustomFieldType, TaskList} from "../../model/TaskList";
import {Button} from "../../forms/Button";
import {MouseEventHandler, useEffect, useRef, useState} from "react";
import * as Yup from 'yup';
import {AnimatePresence, motion} from "framer-motion";
import {OpacityAnimation} from "./TaskAnimations";
import {ContentState, convertFromRaw, convertToRaw, Editor, EditorState} from "draft-js";
import {getEditorContent} from "../../utilities/EditorUtilities";

export const TaskAddEditComp = (props: AddEditTaskProps) => {

    const {
        showComponent, editHandler, task, setTask,
        editMode, cancelHandler, deleteHandler, selectedTaskList
    } = props;

    /* Local states */
    const [errorMessage, setErrorMessage] = useState("");
    const [editorState, setEditorState]
        = useState(EditorState.createWithContent(getEditorContent(task.description)));
    const [editorFocused, setEditorFocused] = useState<boolean>(false);

    /* Local Refs */
    const startRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<Editor>(null);


    /* Hooks */
    useEffect(() => {
        setEditorState(EditorState.createWithContent(getEditorContent(task.description)));
        if (showComponent && startRef && startRef.current) {
            startRef.current.focus();
        }
        setErrorMessage("");
    }, [showComponent]);

    useEffect(() => {
        schema.validate({myString: task.name})
            .then(() => {
                setErrorMessage("");
            })
            .catch(() => {
                setErrorMessage("Task name required");
            })
    }, [task.name]);

    /* Handle active components */
    const handleName = (name: string) => {
        setTask({...task, name});
    }

    const handleDescription = (content: EditorState) => {
        setEditorState(content);
        const description = JSON.stringify(convertToRaw(content.getCurrentContent()));
        setTask({...task, description});
    }

    const handleResponsible = (responsible: any[]) => {
        let resplist: UserBasic[] = [];
        if (responsible) {
            responsible.forEach(item => {
                const resp = task.taskList?.participants.find((user) => user.id === item.value);
                if (resp) {
                    resplist.push({id: resp.id!, name: resp.name, alias: resp.alias});
                }
            })
        }
        setTask({...task, responsible: resplist});
        // console.log(responsible);
    }

    // const handleTaskList = (taskListId: number) => {
    //     const taskList = taskLists.find((item) => item.id === taskListId) as TaskListShort;
    //     setTask({...task, taskList});
    // }

    const handleStartTime = (value: Date) => {
        const startTime = dayjs(value).format("YYYY-MM-DD");
        if (new Date(Date.parse(task.endTime)) < value) {
            const endTime = startTime;
            // const endTime = dayjs(value).add(1, 'm').format("YYYY-MM-DD");
            setTask({...task, startTime, endTime});
        } else {
            setTask({...task, startTime});
        }
    }

    const handleEndTime = (value: Date) => {
        const endTime = dayjs(value).format("YYYY-MM-DD");
        if (new Date(Date.parse(task.startTime)) > value) {
            const startTime = endTime;
            console.log(startTime);
            setTask({...task, startTime, endTime});
        } else {
            setTask({...task, endTime});
        }
    }

    const handleDuration = (value: number, field: string, max?: number) => {
        const localTask = {...task};
        localTask[field] = Math.max(value, 0);
        if (max) {
            localTask[field] = Math.min(localTask[field], max);
        }
        setTask(localTask);
    }

    const changeDuration = (duration: number, inc: number, field: string, max?: number) => {
        // console.log(duration);
        if (duration !== null && duration !== undefined) {
            handleDuration(duration + inc, field, max);
        } else {
            handleDuration(0, field, max);
        }
    }

    const handleCustomField = (name: string, value: string) => {
        const cstFld: Map<string, string> = new Map(task.customFields);
        // console.log(cstFld);
        cstFld.set(name, value);
        setTask({...task, customFields: cstFld});
    }

    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            handleSubmit();
        }
        if (key === "Escape") {
            cancelHandler();
        }
    }

    /* Validation */
    const schema = Yup.object().shape({
        myString: Yup.string().required("Task name required")
    });

    const handleSubmit = () => {
        schema.validate({myString: task.name})
            .then(() => {
                editHandler(task);
                setErrorMessage("");
            })
            .catch(() => {
                setErrorMessage("Task name required");
            })
    }

    const convertContent = (content: string) => {
        try {
            return convertFromRaw(JSON.parse(content));
        } catch (e) {
            return ContentState.createFromText(content);
        }
    }

    const handleEditorClick = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    }

    /* Testing */
    const testing = () => {
        console.log(task);
    }


    if (!showComponent) return null;

    return (
        <div className="modalwindow">
            <div className="modalcontent">
                <div className="modalheader horizontal">
                    <h3 className="modaltitle heading-font1">
                        {editMode ? "Edit task" : "Add new task"}
                    </h3>
                </div>
                <div className="modalbody gap-2 mb-3">
                    <AnimatePresence>
                        {errorMessage !== "" &&
                            <motion.div
                                {...OpacityAnimation}
                                key={1}
                                className="error-message"
                                style={{top: "-30px"}}
                            >
                                {errorMessage}
                            </motion.div>
                        }
                    </AnimatePresence>
                    <div className="" style={{minWidth: "100px", width: "100%"}}>
                        <InputSelectField
                            value={task.name}
                            handleAction={handleName}
                            type={InputSelectType.input}
                            label="Task name"
                            ref={startRef}
                            handleKeyAction={handleKeyUp}
                            error={errorMessage !== ""}
                        />
                    </div>
                    <div className="" style={{minWidth: "100px", width: "100%"}}>
                        <InputSelectField
                            value={{label: task.priority ? task.priority + "" : ""}}
                            handleAction={(value: DataOptionsUnit) => {
                                setTask({...task, priority: value.value})
                            }}
                            type={InputSelectType.select}
                            optionsArray={[1, 2, 3, 4, 5]}
                            label="Priority"
                            handleKeyAction={handleKeyUp}
                        />
                    </div>
                    <div className="" style={{minWidth: "150px", width: "100%"}}>
                        <InputSelectField
                            value={{value: task.taskList?.id, label: task.taskList?.name}}
                            handleAction={null}
                            type={InputSelectType.select}
                            label="Task List"
                            // optionsArray={taskLists}
                            isClearable={false}
                            disable={true}
                            handleKeyAction={handleKeyUp}
                        />
                    </div>
                    <div className="" style={{minWidth: "100px", width: "100%"}}>
                        <InputSelectField
                            value={task.responsible?.map(item => {
                                return {value: item.id, label: item.alias !== "" ? item.alias : item.name};
                            })}
                            handleAction={handleResponsible}
                            type={InputSelectType.select}
                            label="Responsible"
                            optionsArray={task.taskList ? task.taskList.participants : []}
                            isClearable={true}
                            isMulti={true}
                            handleKeyAction={handleKeyUp}
                        />
                    </div>
                    {/*<div style={{minWidth: "150px", width: "100%"}}>*/}
                    {/*    <InputSelectField value={task.description} handleAction={handleDescription}*/}
                    {/*                      type={InputSelectType.textarea}*/}
                    {/*                      label="Description"*/}
                    {/*                      handleKeyAction={handleKeyUp}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="input-wrapper">
                        <div style={{
                            minWidth: "150px",
                            width: "100%",
                            boxShadow: editorFocused ? "0 0 0 1px dodgerblue" : "none",
                            borderColor: editorFocused ? "dodgerblue" : "#aaa",
                        }}
                             className="input-text"
                             onClick={handleEditorClick}
                        >
                            <Editor editorState={editorState}
                                    onChange={handleDescription}
                                    ref={editorRef}
                                    onFocus={() => setEditorFocused(true)}
                                    onBlur={() => setEditorFocused(false)}
                            />
                        </div>
                        <label className="textlabel"
                               style={{backgroundColor: "white"}}>Description</label>
                    </div>
                    <div className="horizontal shrink-container">
                        <div style={{minWidth: "50px"}}>
                            <InputSelectField
                                value={task.startTime}
                                handleAction={handleStartTime}
                                type={InputSelectType.datetime}
                                label="Start time"
                                format="yyyy-MM-dd"
                                handleKeyAction={handleKeyUp}
                            />
                        </div>
                        <div className="ms-2" style={{minWidth: "50px"}}>
                            <InputSelectField
                                value={task.endTime}
                                handleAction={handleEndTime}
                                type={InputSelectType.datetime}
                                label="End time"
                                format="yyyy-MM-dd"
                                handleKeyAction={handleKeyUp}
                            />
                        </div>
                    </div>
                    <div className="center-horizontally shrink-container">
                        <div className="ms-2" style={{width: "120px", display: "flex"}}>
                            <NumberField
                                value={task.taskDuration}
                                handleAction={(value: number) => handleDuration(value, "taskDuration")}
                                handleKeyAction={handleKeyUp}
                                incAction={() => changeDuration(task.taskDuration, 1, "taskDuration")}
                                decAction={() => changeDuration(task.taskDuration, -1, "taskDuration")}
                                label={"Est. hours"}
                            />
                        </div>
                        <div className="ms-2" style={{width: "120px"}}>
                            <NumberField
                                value={task.spentHours}
                                handleAction={(value: number) => handleDuration(value, "spentHours")}
                                handleKeyAction={handleKeyUp}
                                incAction={() => changeDuration(task.spentHours, 1, "spentHours")}
                                decAction={() => changeDuration(task.spentHours, -1, "spentHours")}
                                label={"Spent hours"}
                            />
                        </div>
                        <div className="ms-2" style={{width: "120px"}}>
                            <NumberField
                                value={task.completePercentage}
                                handleAction={(value: number) => handleDuration(value, "completePercentage", 100)}
                                handleKeyAction={handleKeyUp}
                                incAction={() => changeDuration(task.completePercentage, 5, "completePercentage", 100)}
                                decAction={() => changeDuration(task.completePercentage, -5, "completePercentage", 100)}
                                label={"Compl. %"}
                            />
                        </div>
                    </div>

                    <div>
                        {selectedTaskList?.customFields && selectedTaskList?.customFields.map((item, index) => {
                            return (
                                <div key={index}>
                                    {(item.type === CustomFieldType.list) &&
                                        <InputSelectField
                                            value={{
                                                label: task.customFields.get(item.name),
                                                value: task.customFields.get(item.name)
                                            }}
                                            handleAction={(value: any) => handleCustomField(item.name, value.value)}
                                            type={InputSelectType.select}
                                            label={item.name}
                                            optionsArray={
                                                selectedTaskList && selectedTaskList.listParameters &&
                                                selectedTaskList.listParameters.find(list => list.name === item.list) ?
                                                    // @ts-ignore
                                                    selectedTaskList.listParameters
                                                        .find(list => list.name === item.list).list
                                                    : ["Error: check your task list, if related list exists"]
                                            }
                                            handleKeyAction={handleKeyUp}
                                            isClearable={true}
                                        />
                                    }
                                    {(item.type === "value") &&
                                        <InputSelectField
                                            value={task.customFields.get(item.name)}
                                            handleAction={(value: any) => handleCustomField(item.name, value)}
                                            type={InputSelectType.input}
                                            label={item.name}
                                            handleKeyAction={handleKeyUp}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>

                    <div className="mt-2" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className="horizontal" style={{display: "flex", gap: "0.5rem"}}>
                            <Button className="but but-primary"
                                    onClick={handleSubmit}
                                    disabled={errorMessage !== ""}
                                    style={{width: "90px"}}
                            >
                                {editMode ? "Update" : "Add"}
                            </Button>
                            <Button className="but but-light"
                                    onClick={cancelHandler}
                                    style={{width: "90px"}}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div style={{position: "relative"}}>
                        <div style={{position: "absolute", right: 0, top: "-29px"}}>
                            {editMode ?
                                <Button className="but but-warning"
                                        onClick={() => deleteHandler(task.id!)}>
                                    <i className="fa fa-trash-o" style={{display: "flex"}}></i>
                                </Button>
                                : null}
                        </div>
                    </div>
                </div>
                {/*<button onClick={() => console.log(task)}>Test</button>*/}
            </div>
        </div>

    )
}

class AddEditTaskProps {
    showComponent: boolean;
    editHandler: Function;
    cancelHandler: Function;
    deleteHandler: Function;
    task: Task;
    setTask: Function;
    // taskLists: TaskListShort[];
    editMode: boolean;
    selectedTaskList: TaskList | null;
}


const NumberField = (props: NumberFieldProps) => {

    const {value, label, handleAction, handleKeyAction, incAction, decAction} = props;

    return (
        <div className="d-flex">
            <InputSelectField
                value={value}
                handleAction={handleAction}
                type={InputSelectType.number}
                label={label}
                className="text-end"
                handleKeyAction={handleKeyAction}
            />
            <div style={{marginTop: "-0.2rem"}}>
                <button className="btn btn-sm"
                        onClick={incAction}
                        tabIndex={-1}
                >
                    <i className="fa fa-angle-up"></i>
                </button>
                <br/>
                <button className="btn btn-sm"
                        onClick={decAction}
                        tabIndex={-1}
                >
                    <i className="fa fa-angle-down"></i>
                </button>
            </div>
        </div>
    )
}

interface NumberFieldProps {
    value: number;
    handleAction: Function;
    handleKeyAction: Function;
    incAction: MouseEventHandler<HTMLButtonElement>;
    decAction: MouseEventHandler<HTMLButtonElement>;
    label: string;
}