import img from "../resources/loader12.gif";

const Loader = () => {
    return (
        <div>
            <div className="center-horizontally mt-5">
                <img src={img} alt="" style={{scale: "1"}}/>
            </div>
        </div>
    )
}

export default Loader;