import React, {useEffect, useRef, useState} from "react";
import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {Button} from "../../forms/Button";
import * as Yup from "yup";
import {emailSchema, yupErrorList} from "../../services/ValidationService";
import {AnimatePresence, motion} from "framer-motion";
import {ErrorMessage} from "../SignUpComp";
import {useHttp} from "../../hooks/http.hook";
import {beurl} from "../../constants/Constants";
import {ShrinkAnimation} from "../TaskComp/TaskAnimations";

export const ChangeEmailComp = (props: ChangeEmailCompProps) => {
    /* Local constants */
    const request = useHttp();
    const url = beurl(window.location.host.split(':')[0]) + "api/current/";

    /* Local constants*/
    const {onSuccess, onCancel} = props;

    /* Local Refs */
    const startRef = useRef<HTMLInputElement>(null);

    /* Local states */
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [message, setMessage] = useState("");

    /* Hooks */
    useEffect(() => {
        if (startRef && startRef.current) {
            // setTimeout(() => {
            startRef.current!.focus();
            // }, 200)
        }
    }, []);

    /* Web requests */
    const updateEmail = (email: string) => {
        request(url + "email", "POST", JSON.stringify(email))
            .then(() => {
                onSuccess();
            })
            .catch((e) => {
                console.log(e.message);
                setMessage(e.message);
                // console.log(errors);
            })
    }

    /* Handlers */
    const handleEmail = (value: string) => {
        setEmail(value);
        setMessage("");
    }

    /* Key press handler */
    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            handleSubmit();
        }
        if (key === "Escape") {
            onCancel();
        }
    }

    /* Validation */
    const schema = Yup.object().shape({
        email: emailSchema
    });

    const handleSubmit = () => {
        schema.validate({email: email}, {abortEarly: false})
            .then(() => {
                setErrors({});
                updateEmail(email);
            })
            .catch((err) => {
                if (err instanceof Yup.ValidationError) {
                    setErrors(yupErrorList(err));
                }
            })
    }


    return (
        <div className="modalwindow">
            <div className="modalcontent">
                <div className="modalheader">
                    <h3 className="modaltitle heading-font1">
                        Change e-mail
                    </h3>
                </div>
                <div className="center-horizontally">
                    Enter new e-mail.
                </div>
                <div className="mt-4">
                    <InputSelectField value={email}
                                      handleAction={handleEmail}
                                      type={InputSelectType.input}
                                      label="E-mail" backgroundcolor="#fafafa"
                                      ref={startRef}
                                      handleKeyAction={handleKeyUp}
                                      error={errors.hasOwnProperty("email")}
                    />
                </div>
                <AnimatePresence>
                    {errors.hasOwnProperty("email") &&
                        <ErrorMessage message={errors["email"]}/>
                    }
                </AnimatePresence>
                <div className="mt-3" style={{height: "1.5rem"}}>
                    <AnimatePresence>
                        {message !== "" &&
                            <motion.div
                                {...ShrinkAnimation}
                                className="center-horizontally error-text overflow-hidden"
                            >
                                {message}
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>

                <div className="d-flex center-all mt-1 gap-2">
                    <div>
                        <Button className="but but-primary but-sm"
                                onClick={() => handleSubmit()}>Update e-mail
                        </Button>
                    </div>
                    <div>
                        <Button className="but but-warning but-sm" onClick={() => onCancel()}>Cancel
                        </Button>
                    </div>
                </div>

            </div>
        </div>

    )
}

class ChangeEmailCompProps {
    onSuccess: Function;
    onCancel: Function;
}