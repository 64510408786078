export class Constants {
    static loginPage = "/login";
}

export const beurl = (url: string): string => {
    return process.env.NODE_ENV === "development" ?
        "http://" + url + ":8080/" :
        "https://" + url + "/"
}
export const feurl = (url: string): string => {
    return process.env.NODE_ENV === "development" ?
        "http://" + url + ":3000/" :
        "https://" + url + "/"
}
export const googlelogin = (url: string): string => {
    return process.env.NODE_ENV === "development" ?
        "http://" + url + ":8080/api/oauth2/authorize/google" :
        "https://" + url + "/api/oauth2/authorize/google"
}
export const fblogin = (url: string): string => {
    return process.env.NODE_ENV === "development" ?
        "http://" + url + ":8080/api/oauth2/authorize/facebook" :
        "https://" + url + "/api/oauth2/authorize/facebook"
}
