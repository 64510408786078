import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {useEffect, useRef, useState} from "react";
import {Button} from "../../forms/Button";
import * as Yup from "yup";
import {AnimatePresence} from "framer-motion";
import {ErrorMessage} from "../SignUpComp";
import {passwordConfirmSchema, passwordSchema, yupErrorList} from "../../services/ValidationService";

export const ChangePasswordComp = (props: ChangePasswordCompProps) => {

    /* Local constants*/
    const {updatePassword, onCancel} = props;

    /* Local Refs */
    const startRef = useRef<HTMLInputElement>(null);

    /* Local states */
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (startRef && startRef.current) {
            // setTimeout(() => {
            startRef.current!.focus();
            // }, 200)
        }
    }, []);

    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            handleSubmit();
        }
        if (key === "Escape") {
            onCancel();
        }
    }

    /* Validation */
    const schema = Yup.object().shape({
        password: passwordSchema,
        confirmPassword: passwordConfirmSchema
    });

    const handleSubmit = () => {
        schema.validate({password: password1, confirmPassword: password2}, {abortEarly: false})
            .then((data) => {
                updatePassword(password1);
                setErrors({});
                // console.log("Validation succeeded. ");
                // console.log(data);
            })
            .catch((err) => {
                if (err instanceof Yup.ValidationError) {
                    setErrors(yupErrorList(err));
                }
            })
    }

    return (
        <div className="modalwindow">
            <div className="modalcontent">
                <div className="modalheader">
                    <h3 className="modaltitle heading-font1">
                        Change password
                    </h3>
                </div>
                <div className="center-horizontally">
                    Enter new password.
                </div>
                <div className="mt-4">
                    <InputSelectField value={password1} handleAction={setPassword1}
                                      type={showPassword ? InputSelectType.input : InputSelectType.password}
                                      label="Password" backgroundcolor="#fafafa"
                                      handleKeyAction={handleKeyUp}
                                      ref={startRef}
                                      error={errors.hasOwnProperty("password")}
                    />
                </div>
                <AnimatePresence>
                    {errors.hasOwnProperty("password") &&
                        <ErrorMessage message={errors["password"]}/>
                    }
                </AnimatePresence>
                <div className="mt-4">
                    <InputSelectField value={password2} handleAction={setPassword2}
                                      type={showPassword ? InputSelectType.input : InputSelectType.password}
                                      label="Password confirmation" backgroundcolor="#fafafa"
                                      handleKeyAction={handleKeyUp}
                                      error={errors.hasOwnProperty("confirmPassword")}
                    />
                </div>
                <AnimatePresence>
                    {errors.hasOwnProperty("confirmPassword") &&
                        <ErrorMessage message={errors["confirmPassword"]}/>
                    }
                </AnimatePresence>

                <div className="center-all mt-3">
                    <InputSelectField value={showPassword} handleAction={setShowPassword}
                                      type={InputSelectType.checkbox}
                                      label="- show password"
                                      handleKeyAction={handleKeyUp}
                    />
                </div>

                <div className="d-flex center-all mt-3 gap-2">
                    <div>
                        <Button className="but but-primary but-sm"
                                onClick={handleSubmit}>Save password
                        </Button>
                    </div>
                    <div>
                        <Button className="but but-warning but-sm" onClick={() => onCancel()}>Cancel
                        </Button>
                    </div>
                </div>

            </div>
        </div>

    )
}

class ChangePasswordCompProps {
    updatePassword: Function;
    onCancel: Function;
}