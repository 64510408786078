import {configureStore} from "@reduxjs/toolkit";
import {taskFilterSlice, userSlice, userSettingsSlice, sorterSlice} from "../reducers";
import {enableMapSet} from "immer";

enableMapSet();

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        taskfilter: taskFilterSlice.reducer,
        usersettings: userSettingsSlice.reducer,
        sorter: sorterSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})