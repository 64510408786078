import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {beurl, Constants, fblogin, googlelogin} from "../../constants/Constants";
import {useHttp} from "../../hooks/http.hook";
import {useDispatch} from "react-redux";
import {taskFilterSlice, userSlice} from "../../reducers";
import {logoutUser} from "../../services/UserService";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import Cookies from "js-cookie";
import PrivacyPolicyDetailsModal from "./PrivacyPolicyDetailsModal";
import {Button} from "../../forms/Button";
import {AnimatePresence, motion} from "framer-motion";
import {OpacityAnimation, ShrinkAnimation} from "../TaskComp/TaskAnimations";

export const LoginComp = () => {

    /* Constants */
    const url = beurl(window.location.host.split(':')[0]) + "api/login";
    const request = useHttp();

    /* Redux */
    const dispatch = useDispatch();
    const {setUser} = userSlice.actions;
    const {resetTaskFilter} = taskFilterSlice.actions;
    // const tokenObject = useSelector<RootUser, string | null>(state => state.username.username);

    /* Local states */
    const [userName, setUserNameLocal] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        checkPolicy();
     }, []);

    const checkPolicy = () => {
        const privacyPolicyAccepted = Cookies.get('privacyPolicyAccepted');
        if (!privacyPolicyAccepted) {
            console.log(privacyPolicyAccepted);
            setShowPrivacyPolicy(true);
        }
    }

    const handleAccept = (): void => {
        Cookies.set('privacyPolicyAccepted', 'true');
        setShowDetails(false);
        setShowPrivacyPolicy(false);
    };


    /* Functions */
    const authenticate = () => {
        handleAccept();
        // console.log(url);
        setErrorMessage("");
        request(url + "/login", "POST", JSON.stringify({name: userName, password}))
            .then(data => {
                dispatch(setUser(data));
                dispatch(resetTaskFilter(""));
                if (data.status === "Active") {
                    navigate("/tasks");
                } else {
                    navigate("/settings")
                }
            })
            .catch((e) => {
                // console.log("Failed to authenticate.")
                setErrorMessage(e.message);
                // setErrorMessage("Failed to authenticate.")
                logoutUser();
            })
    }

    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            authenticate();
        }
    }

    const handleKeyUPassword = (key: string) => {
        if (key === "Enter") {
            authenticate();
        }
        if (key === "Escape") {
            setPassword("");
        }
    }

    return (
        <motion.div
            {...OpacityAnimation}
        >
            <div className="center-all">
                <div className="login-plate mt-5">
                    <div className="heading1">Login</div>
                    <div className="mt-4">
                        <InputSelectField value={userName} handleAction={setUserNameLocal} type={InputSelectType.input}
                                          label="User Name" backgroundcolor="#fafafa"
                                          handleKeyAction={handleKeyUp}
                        />
                    </div>
                    <div className="mt-4">
                        <InputSelectField value={password} handleAction={setPassword}
                                          type={showPassword ? InputSelectType.input : InputSelectType.password}
                                          label="Password" backgroundcolor="#fafafa"
                                          handleKeyAction={handleKeyUPassword}
                        />
                    </div>
                    <div className="center-all">
                        <InputSelectField value={showPassword} handleAction={setShowPassword}
                                          type={InputSelectType.checkbox}
                                          label="- show password"/>
                    </div>
                    <div className="" style={{height: "1.5rem"}}>
                        <AnimatePresence>
                            {errorMessage !== "" &&
                                <motion.div
                                    {...ShrinkAnimation}
                                    className="center-horizontally error-text overflow-hidden"
                                >
                                    {errorMessage}
                                </motion.div>
                            }
                        </AnimatePresence>
                    </div>
                    <div className="center-all">
                        <Button className="but but-primary but-sm" onClick={authenticate}>Login</Button>
                    </div>
                    <div className="d-flex mt-3">
                        <div className="left-all me-auto">
                            <a className="small-link d-flex gap-2"
                               href={googlelogin(window.location.host.split(':')[0])}
                            >
                                <img src="/Google_logo.png" alt="Google" width="25" height="25"/>
                                Google
                            </a>
                            <a className="small-link d-flex gap-2 mt-1"
                               href={fblogin(window.location.host.split(':')[0])}
                            >
                                <img src="/Facebook_icon.png" alt="Facebook" width="25" height="25"/>
                                Facebook
                            </a>
                        </div>
                        <div className="right-all ms-auto">
                            <div className="small-link fw-bold" onClick={() => navigate('/signup/')}>
                                Sign Up
                            </div>
                            <div className="small-link mt-1" onClick={() => navigate('/passwordreset/')}>
                                Forgot password
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <PrivacyPolicyModal
                    show={showPrivacyPolicy}
                    setShowDetails={setShowDetails}
                    handleAccept={handleAccept}
                    setShow={setShowPrivacyPolicy}
                />
                <PrivacyPolicyDetailsModal
                    show={showDetails}
                    setShowDetails={setShowDetails}
                    handleAccept={handleAccept}
                />
                {/* Rest of your application */}
            </div>

        </motion.div>
    )
}
