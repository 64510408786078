import React from 'react';
import Cookies from 'js-cookie';
import {Button} from "../../forms/Button";
import {PolicyAndService} from "./PolicyAndService";

interface PrivacyPolicyDetailsModalProps {
    show: boolean;
    setShowDetails: (show: boolean) => void;
    handleAccept: () => void;
}

const PrivacyPolicyDetailsModal: React.FC<PrivacyPolicyDetailsModalProps> = ({show, setShowDetails, handleAccept}) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modalwindow">
            <div className="details-modal">
                <div style={{position: "relative"}}>

                    <PolicyAndService/>

                    <div className="center-horizontally">
                        <Button className="but but-primary" onClick={handleAccept}>Accept</Button>
                    </div>
                    <div style={{position: "absolute", top: "-2rem", right: "-2rem"}}>
                        <Button className="btn" onClick={() => setShowDetails(false)}>
                            <i className="fa fa-close"/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyDetailsModal;
