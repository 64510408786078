import React from 'react';
import Cookies from 'js-cookie';
import {Button} from "../../forms/Button";

interface PrivacyPolicyModalProps {
    show: boolean;
    setShowDetails: (show: boolean) => void;
    setShow: (show: boolean) => void;
    handleAccept: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({show, setShowDetails, handleAccept, setShow}) => {
    if (!show) {
        return null;
    }

    return (
        <div style={{position: "absolute", left: "0", bottom: "0", width: "100%"}}>
        <div className="" style={{position: "relative"}}>
            <div className="privacy-modal">
                <div style={{position: "relative"}}>
                    <div className="center-horizontally">
                        <p>This application uses a valid email address for user notifications and password recovery.
                            Cookies are also used for authentication and to enhance the usability of the application.</p>
                    </div>
                    <div className="d-flex gap-2 center-horizontally">
                        <Button className="but but-light" onClick={() => setShowDetails(true)}>Details</Button>
                        <Button className="but but-primary" onClick={handleAccept}>Accept</Button>
                    </div>
                    <div style={{position: "absolute", top: "-1rem", right: "-1rem"}}>
                        <Button className="btn" onClick={() => setShow(false)}>
                            <i className="fa fa-close"/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default PrivacyPolicyModal;
