import { motion } from "framer-motion"
import {OpacityAnimation} from "./TaskComp/TaskAnimations";

export const ExpiredLogin = () => {
    return (
        <motion.div
            {...OpacityAnimation}
        >
            <div className="center-horizontally">
                <img src="/expired.png" alt="Task Simple" height="400"/>
            </div>
            <h1 className="heading-font1 center-horizontally">
                Session has expired. Please, login again.
            </h1>
        </motion.div>
    )
}