export const useHttp = () => {
    const request = async (
        url: any,
        method: string = 'GET',
        body: BodyInit | null | undefined = null,
        authToken: string | null = null,
        headers: any = {'Content-type': 'application/json'}
    ) => {
        if (authToken) {
            headers = {'Authorization': `Bearer ${authToken}`, ...headers}
        }
        try {
            const response: Response = await fetch(url, {method, body, headers, credentials: "include"});
            if (!response.ok) {
                throw new Error(`Could not fetch ${url}, status: ${response.status}.`, {cause: response.status});
            }
            const data = response;
            if (data.headers.get('Content-Type') === 'application/json') {
                return data.json();
            }
            return;
        } catch (e) {
            throw e;
        }
    }

    return request;
}
