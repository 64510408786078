import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {Button} from "../../forms/Button";
import {useEffect, useRef, useState} from "react";
import {motion, PanInfo, useAnimation} from "framer-motion";
import {TaskColumnSettings} from "../TaskComp/TaskColumnSettings";
import {number, string} from "yup";
import {forEach} from "react-bootstrap/ElementChildren";
import {current} from "@reduxjs/toolkit";

export const TaskListParametersEditor = (props: ListParametersEditorProps) => {

    const {showComponent, list, saveHandler, cancelHandler} = props;

    const lineHeight: number = 45;

    /* Local states */
    const [localList, setLocalList] = useState<{id: number,  value: string}[]>([]);
    const [draggable, setDraggable] = useState<"x" | "y" | boolean | undefined>(false);
    const [dragged, setDragged] = useState(-1);

    const controls = useAnimation();
    const cellRef = useRef<HTMLDivElement>(null);

    /* Hooks */
    useEffect(() => {
        if (showComponent) {
            if (list) {
                // console.log(list);
                setLocalList(list.map((item, index) => {return {id: index, value: item}}));
            }
        }
    }, [showComponent]);

    /* Handle active components */
    const addToList = () => {
        if (localList) {
            const lst = [...localList];
            lst.push({id: lst.length, value: ""});
            setLocalList(lst);
        } else {
            setLocalList([{id: 0, value: ""}]);
        }
    }

    const handleListEntry = (text: string, index: number) => {
        if (localList) {
            const lst = [...localList];
            lst[index].value = text;
            setLocalList(lst);
        }
    }

    const deleteEntry = (index: number) => {
        const lst = [...localList];
        lst.splice(index, 1);
        setLocalList(lst);
    }

    const handleDraggable = (event: React.MouseEvent, index: number) => {
        if (dragged < 0) {
            if (cellRef && cellRef.current) {
                for (let child of cellRef.current.children) {
                    (child as HTMLDivElement).style.zIndex = "0";
                    (child as HTMLDivElement).style.position = "relative";
                }
            }
            if ((event.currentTarget as HTMLDivElement).parentElement && (event.currentTarget as HTMLDivElement).parentElement?.parentElement) {
                // @ts-ignore
                (event.currentTarget as HTMLDivElement).parentElement.parentElement.style.zIndex = "1000";
            }
            // setDragged(index);
            setDraggable("y");
        }
    }

    const resetDraggable = (index: number) => {
        if (dragged < 0) {
            setDraggable(false);
        }
    }
    const handleDragEnd = (e: MouseEvent | PointerEvent | TouchEvent, info: PanInfo, index: number) => {
        // console.log(`Drag ended at x=${info.point.x} and y=${info.point.y}.`);
        const destination = Math.floor(Math.max(
            (cellRef.current ? info.point.y - cellRef.current.getBoundingClientRect().y : 0) / lineHeight, 0));
        // console.log(`Top coordinate is ${cellRef.current ? cellRef.current.getBoundingClientRect().y : 0}.`);
        // console.log(`Destination is ${destination} and source is ${index}.`);
        const list = [...localList];
        const elem = list[index];
        list.splice(index, 1);
        list.splice(destination, 0, elem);
        setLocalList(list);
        setDragged(-1);
        controls.start({
            x: 0,
            y: 0,
            transition: {type: 'spring', stiffness: 300, damping: 25},
        });

    }


    if (!showComponent) {
        return null;
    }

    return (
        <div className="modalwindow">
            <div className="modalcontent">
                <div className="modalheader">
                    <h3 className="modaltitle">
                        Add values
                    </h3>
                </div>
                <div className="modalbody gap-2 d-grid justify-content-center">
                    <div className="d-grid justify-content-center">
                        <div className="d-flex align-items-center">
                            Add value
                            <Button className="btn" onClick={addToList}>
                                <i className="fa fa-plus-circle fa-2x color-blue"></i>
                            </Button>
                        </div>
                    </div>
                    <div ref={cellRef}>
                        {localList.map((item, index) => {
                            return (
                                <motion.div
                                    layout={true}
                                    animate={controls}
                                    drag={draggable}
                                    onDragEnd={(event, info) => handleDragEnd(event, info, index)}
                                    onDrag={() => setDragged(index)}
                                    key={item.id}
                                    style={{height: lineHeight + "px"}}
                                    className="center-vertically-div"
                                >
                                    <div key={index} className="d-flex gap-2">
                                        <button className="but but-extralight but-sm"
                                                onMouseEnter={(event) => handleDraggable(event, index)}
                                                onMouseLeave={() => resetDraggable(index)}>
                                            <i className="fa fa-exchange fa-rotate-90"></i>
                                        </button>

                                        <div style={{marginTop: "-13px"}}>
                                            <InputSelectField
                                                value={item.value}
                                                handleAction={(value: string) => handleListEntry(value, index)}
                                                type={InputSelectType.input}
                                            />
                                        </div>

                                        <div className="">
                                            <Button className="but but-warning but-sm"
                                                    onClick={() => deleteEntry(index)}>
                                                <i className="fa fa-trash-o"/>
                                            </Button>
                                        </div>
                                    </div>
                                </motion.div>
                            )
                        })}
                    </div>
                </div>

                <div className="pt-3" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div className="center-horizontally gap-2">
                        <Button className="but but-primary" onClick={() => saveHandler(localList.map(item => item.value))}>Save</Button>
                        <Button className="but but-light" onClick={cancelHandler}>Cancel</Button>
                    </div>
                </div>
            </div>
            {/*<Button className="but" onClick={() => console.log(localList)}>Test</Button>*/}
        </div>

    )
}

class ListParametersEditorProps {
    showComponent: boolean;
    list: string[];
    saveHandler: Function;
    cancelHandler: Function;
}