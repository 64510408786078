import {CSSProperties, useEffect, useRef} from "react";
import {convertToRaw} from "draft-js";
import {marked} from "marked";
import {getEditorContent} from "../utilities/EditorUtilities";

export const CollapsibleRow2 = (props: CollapsibleRowProps) => {

    const {
        children, index, onClick,
        showIndex, style, className, textClassName,
        showAll
    } = props;

    const cellRef = useRef(null);

    useEffect(() => {
        if (index === showIndex || showAll) {
            adjustHeight();
        } else {
            if (cellRef.current) {
                (cellRef.current as HTMLDivElement).style.maxHeight = '0';
            }
        }
    }, [showIndex, showAll]);


    const adjustHeight = () => {
        if (cellRef?.current) {
            (cellRef.current as HTMLDivElement).style.height = 'auto';
            (cellRef.current as HTMLDivElement).style.maxHeight = 'auto';
            (cellRef.current as HTMLDivElement).style.maxHeight = `${(cellRef.current as HTMLDivElement).scrollHeight}px`;
        }
    };

    // Function to convert content to Markdown
    const convertContentToMarkdown = (text: string): string => {
        const rawContent = convertToRaw(getEditorContent(text));
        let markdownString = '';
        rawContent.blocks.forEach((block) => {
            markdownString += block.text + '\n';
        });
        return marked(markdownString) as string;
    };


    return (
        <div className={className} style={style} onClick={(e) => (onClick ? onClick(e) : null)}>
            <div style={{height: "3px"}}
                 className={(index % 2 === 1 ? " task-even" : "")}
            ></div>
            <div ref={cellRef}
                 style={{overflow: 'hidden', transition: "max-height 300ms", maxHeight: "0", paddingLeft: "5%"}}
                 className={"task-description" +
                (showIndex === index || showAll ? " task-description-border" : "") +
                     (index % 2 === 1 ? " task-description-even" : " task-description-odd")
            }
            >
                {/*<div className={"md-output " + textClassName} style={{whiteSpace: "pre-wrap"}}*/}
                <div className={"md-output " + textClassName}
                     dangerouslySetInnerHTML={{__html: convertContentToMarkdown(children)}}/>
            </div>
            {/*<div style={{overflow: 'hidden', transition: "max-height 300ms", maxHeight: "6px", height: "6px"}}></div>*/}
            <div style={{height: "3px"}}
                 className={(index % 2 === 1 ? " task-even" : "")}
            ></div>
        </div>
    )
}

class CollapsibleRowProps {
    children: any;
    index: number;
    showIndex: number;
    showAll: boolean;
    style?: CSSProperties | undefined;
    className?: string;
    textClassName?: string;
    onClick?: Function;
}