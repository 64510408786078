export const ShrinkAnimation = {
    // layout: true,
    initial: {opacity: 0, height: 0},
    animate: {opacity: 1, height: "auto"},
    exit: {opacity: 0, height: 0},
    transition: {duration: 0.2},
}

export const SpringAnimation = {
    layout: true,
    initial: {scale: 0.5},
    animate: {scale: 1},
    transition: {type: "spring", stiffness: 100, damping: 12}
}

export const OpacityAnimation = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
    transition: {duration: 1},
}
