export enum Align {
    left = "left",
    center = "center",
    right = "right",
}

export enum StandardColumns {
    completed = "completed",
    title = "title",
    responsible = "responsible",
    owner = "owner",
    schedule = "schedule",
    start = "start",
    end = "end",
    duration = "duration",
    priority = "priority",
    spentHours = "spentHours",
    completePercentage = "completePercentage"
}

export const defaultColumnSettings = (): Map<string, TaskColumnSettings> => {
    const defColumns = new Map<string, TaskColumnSettings>();
    defColumns.set(StandardColumns.completed, new TaskColumnSettings(1, "Done", true, Align.left, "5%", "70px", 0));
    defColumns.set(StandardColumns.priority, new TaskColumnSettings(2, "Pri", true, Align.left, "5%", "20px", 1));
    defColumns.set(StandardColumns.title, new TaskColumnSettings(3, "Title", true, Align.left, "30%", "150px", 2));
    defColumns.set(StandardColumns.responsible, new TaskColumnSettings(4, "Responsible", true, Align.left, "30%", "100px", 3));
    defColumns.set(StandardColumns.owner, new TaskColumnSettings(5, "Owner", false, Align.left, "20%", "70px", 4));
    defColumns.set(StandardColumns.schedule, new TaskColumnSettings(6, "Schedule", false, Align.left, "25%", "110px", 5));
    defColumns.set(StandardColumns.start, new TaskColumnSettings(7, "Start", false, Align.left, "25%", "110px", 6));
    defColumns.set(StandardColumns.end, new TaskColumnSettings(8, "End", true, Align.left, "25%", "110px", 7));
    defColumns.set(StandardColumns.duration, new TaskColumnSettings(9, "Hours", false, Align.right, "5%", "50px", 8));
    defColumns.set(StandardColumns.spentHours, new TaskColumnSettings(10, "Spent", false, Align.right, "5%", "50px", 9));
    defColumns.set(StandardColumns.completePercentage, new TaskColumnSettings(11, "Compl. %", false, Align.right, "5%", "80px", 10));
    return defColumns;
}

export class TaskColumnSettings {
    [key: string]: any;
    /* Required as a key to be rendered correctly */
    id: number;
    title: string;
    visible: boolean;
    align: Align;
    width: string;
    minWidth: string;
    columnPosition: number;

    constructor(id: number, title: string, visible: boolean, align: Align, width: string, minWidth: string, columnPosition: number) {
        this.id = id;
        this.title = title;
        this.visible = visible;
        this.align = align;
        this.width = width;
        this.minWidth = minWidth;
        this.columnPosition = columnPosition;
    }

}