import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {useHttp} from "../../hooks/http.hook";
import {beurl} from "../../constants/Constants";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import {passwordConfirmSchema, passwordSchema, yupErrorList} from "../../services/ValidationService";
import {AnimatePresence} from "framer-motion";
import {ErrorMessage} from "../SignUpComp";

export const PasswordNewComp = () => {

    /* Local constant */
    const request = useHttp();
    const url = beurl(window.location.host.split(':')[0]) + "api/login/newpassword/"

    /* Local states */
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const { key } = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const navigate = useNavigate();

    /* Web requests */
    const updatePassword = (password: string) => {
        request(url + key, 'POST', JSON.stringify(password))
            .then(() => {
                navigate("/login");
            })
            .catch(() => {
                console.log("Failed to reset password.");
            })
    }

    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            handleSubmit();
        }
        if (key === "Escape") {
            // onCancel();
        }
    }

    /* Validation */
    const schema = Yup.object().shape({
        password: passwordSchema,
        confirmPassword: passwordConfirmSchema
    });

    const handleSubmit = () => {
        schema.validate({password: password1, confirmPassword: password2}, {abortEarly: false})
            .then((data) => {
                updatePassword(password1);
                setErrors({});
                // console.log("Validation succeeded. ");
                // console.log(data);
            })
            .catch((err) => {
                if (err instanceof Yup.ValidationError) {
                    setErrors(yupErrorList(err));
                }
            })
    }


    return (
        <div>
            <div className="center-all">
                <div className="login-plate mt-5">
                    <div className="heading1">New password</div>
                    <div>
                        Enter new password.
                    </div>
                    <div className="mt-4">
                        <InputSelectField value={password1} handleAction={setPassword1}
                                          type={showPassword ? InputSelectType.input : InputSelectType.password}
                                          label="Password" backgroundcolor="#fafafa"
                                          handleKeyAction={handleKeyUp}
                                          error={errors.hasOwnProperty("password")}
                        />
                    </div>
                    <AnimatePresence>
                        {errors.hasOwnProperty("password") &&
                            <ErrorMessage message={errors["password"]}/>
                        }
                    </AnimatePresence>
                    <div className="mt-4">
                        <InputSelectField value={password2} handleAction={setPassword2}
                                          type={showPassword ? InputSelectType.input : InputSelectType.password}
                                          label="Password confirmation" backgroundcolor="#fafafa"
                                          handleKeyAction={handleKeyUp}
                                          error={errors.hasOwnProperty("confirmPassword")}
                        />
                    </div>
                    <AnimatePresence>
                        {errors.hasOwnProperty("confirmPassword") &&
                            <ErrorMessage message={errors["confirmPassword"]}/>
                        }
                    </AnimatePresence>
                    <div className="center-all mt-3">
                        <InputSelectField value={showPassword} handleAction={setShowPassword} type={InputSelectType.checkbox}
                                          label="- show password"/>
                    </div>

                    <div className="center-all mt-3">
                        <button type="button" className="but but-primary but-sm" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}