import {requestHttp} from "./HttpService";
import {beurl} from "../constants/Constants";
import {mapFromJSON, mapToJSON} from "./JsonService";
import {UserBasic} from "../model/UserBasic";
import {store} from "../store";
import {taskFilterSlice, userSettingsSlice, userSlice} from "../reducers";

const beuri = beurl(window.location.host.split(':')[0]);
const usrSettingsUrl = beuri + "api/current/settings";
const loginUrl = beuri + "api/login";

export const saveUserSettings = (settings: Map<string, string>) => {
    requestHttp(usrSettingsUrl, 'POST', JSON.stringify(settings, mapToJSON))
        .catch(e => {
            console.log(e);
        })
}

export const requestUserSettings = async (): Promise<Map<string, string> | null> => {
    return await requestHttp(usrSettingsUrl)
        .then(data => {
            if (data.settings) {
                return JSON.parse(data.settings, mapFromJSON);
            } else {
                return null;
            }
        })
        .catch(e => {
            console.error("Failed to fetch user settings.");
        });
}

export const checkAuthentication = async (): Promise<UserBasic | null> => {
    return await requestHttp(loginUrl + "/isauthenticated")
        .then(data => {
            return data;
        })
        .catch(e => {throw e})
}

export const logoutUser = () => {
    requestHttp(loginUrl + "/logout")
        .then()
        .catch(e => {
            console.error('Logout failed.');
        })
}

export const logout = () => {
    store.dispatch(userSlice.actions.removeUser(""));
    store.dispatch(taskFilterSlice.actions.resetTaskFilter(""));
    store.dispatch(userSettingsSlice.actions.removeSetting(""));
    logoutUser();
}
