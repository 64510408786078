import {DataOptionsUnit, InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {UserBasic} from "../../model/UserBasic";
import {CustomField, CustomFieldType, ListParameter, TaskList} from "../../model/TaskList";
import {Button} from "../../forms/Button";
import {useEffect, useRef, useState} from "react";
import {TaskListParametersEditor} from "./TaskListParametersEditor";
import {useSelector} from "react-redux";
import {AuthUser} from "../../reducers";
import "../styles.scss";
import {nameAlias} from "../../model/User";

export const TaskListAddEdit = (props: AddEditTaskListProps) => {

    const {
        showComponent, saveHandler, taskList, users,
        editMode, cancelHandler, deleteHandler, loggedInUser
    } = props;

    /* Redux */
    const loggedInUserName: string | undefined = (useSelector<AuthUser>(state => state.user) as AuthUser).user?.name;

    /* Local Refs */
    const startRef = useRef<HTMLInputElement>(null);

    /* Local states */
    const [localTaskList, setLocalTaskList] = useState<TaskList | null>(null);
    const [editableListParameterIndex, setEditableListParameterIndex] = useState(-1);
    const [editableListParameter, setEditableListParameter] = useState<string[]>([]);

    /* Hooks */
    useEffect(() => {
        setLocalTaskList(new TaskList());
    }, []);

    useEffect(() => {
        if (showComponent) {
            if (taskList) {
                setLocalTaskList({...taskList});
            } else {
                setLocalTaskList(new TaskList());
            }
            if (startRef && startRef.current) {
                startRef.current!.focus();
            }
        }
    }, [showComponent]);

    /* Handle active components */
    const handleName = (name: string) => {
        if (localTaskList) {
            setLocalTaskList({...localTaskList, name});
        }
    }

    const handleInvitedUsers = (invitedUsers: any[]) => {
        let invitedlist: UserBasic[] = [];
        if (invitedUsers) {
            invitedUsers.forEach(item => {
                const invUsr = users.find((user) => user.id === item.value);
                if (invUsr) {
                    invitedlist.push({id: invUsr.id!, name: invUsr.name, alias: invUsr.alias});
                }
            })
        }
        if (localTaskList) {
            setLocalTaskList({...localTaskList, invitedUsers: invitedlist});
        }
    }

    const handleParticipants = (participants: any[]) => {
        const participantsList: UserBasic[] = [];
        if (participants) {
            const deletedParticipants: UserBasic[] = [];
            taskList.participants.forEach(participant => {
                const prtcp = participants.find((item) => item.value === participant.id);
                if (!prtcp) {
                    deletedParticipants.push(participant);
                }
            })
            taskList.participants.forEach(participant => {
                const deleted = deletedParticipants.find((item) => item.id === participant.id);
                if (!(deleted // NOT ( if participant is deleted AND
                    && deleted.id !== taskList.owner?.id // deleted is not taskList owner AND
                    && (deleted.id === loggedInUser?.id || loggedInUser?.id === taskList.owner?.id))) //(deleted by himself OR deleted by owner) )
                {
                    participantsList.push(participant);
                }
            })
            // participants.forEach(item => {
            //     const prtcp = taskList.participants.find((user) => user.id === item.value);
            //     if (prtcp) {
            //         participantsList.push({id: prtcp.id!, name: prtcp.name, alias: prtcp.alias});
            //     }
            // })
        }
        if (localTaskList) {
            setLocalTaskList({...localTaskList, participants: participantsList});
        }
        // console.log(responsible);
    }

    const handleOwner = (ownerId: number) => {
        let owner = taskList?.participants.find((user) => user.id === ownerId);
        // console.log(taskList?.participants);
        // console.log(ownerId);
        if (localTaskList && owner) {
            setLocalTaskList({...localTaskList, owner});
        }
    }

    const customFieldHandler = (value: any, property: string, index: number) => {
        if (localTaskList && localTaskList.customFields) {
            const cstFld: CustomField[] = localTaskList.customFields;
            cstFld[index][property] = value;
            setLocalTaskList({...localTaskList, customFields: cstFld});
        }
    }

    const addCustomField = () => {
        if (localTaskList) {
            const tskLst = {...localTaskList};
            if (tskLst.customFields) {
                tskLst.customFields.push(new CustomField());
            } else {
                tskLst.customFields = [new CustomField()];
            }
            setLocalTaskList(tskLst);
        }
    }

    const removeCustomField = (index: number) => {
        if (localTaskList) {
            const tskLst = {...localTaskList};
            tskLst.customFields.splice(index, 1);
            setLocalTaskList(tskLst);
        }
    }

    const listParameterHandler = (value: any, property: string, index: number) => {
        if (localTaskList && localTaskList.listParameters) {
            const lstPrm: ListParameter[] = localTaskList.listParameters;
            lstPrm[index][property] = value;
            setLocalTaskList({...localTaskList, listParameters: lstPrm});
        }
    }

    const addListParameter = () => {
        if (localTaskList) {
            const tskLst = {...localTaskList};
            if (tskLst.listParameters) {
                tskLst.listParameters.push(new ListParameter());
            } else {
                tskLst.listParameters = [new ListParameter()];
            }
            setLocalTaskList(tskLst);
        }
    }

    const handleShowListEditor = (listParameter: ListParameter, index: number) => {
        setEditableListParameter(listParameter.list);
        setEditableListParameterIndex(index);
        // tempListParameter = listParameter;
    }

    const deleteListParameter = (index: number) => {
        if (localTaskList?.listParameters) {
            let lstPrm = [...localTaskList.listParameters];
            lstPrm.splice(index, 1);
            // console.log(lstPrm);
            setLocalTaskList({...localTaskList, listParameters: lstPrm} as TaskList);
        }
    }

    const saveListParameters = (list: string[]) => {
        listParameterHandler(list, 'list', editableListParameterIndex);
        setEditableListParameterIndex(-1);
    }

    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            saveHandler(localTaskList);
        }
        if (key === "Escape") {
            cancelHandler();
        }
    }

    if (!showComponent) return null;

    return (
        <div className="modalwindow">
            <div className="modalcontent">
                <div className="modalheader text-center">
                    <div className="modaltitle heading-font1">
                        {editMode ? "Edit task list" : "Add  a new task list"}
                    </div>
                </div>
                <div className="modalbody gap-2">
                    <div className="" style={{minWidth: "100px", width: "100%"}}>
                        <InputSelectField value={localTaskList?.name} handleAction={handleName}
                                          type={InputSelectType.input}
                                          label="Task List name"
                                          ref={startRef}
                                          handleKeyAction={handleKeyUp}
                        />
                    </div>
                    <div className="" style={{minWidth: "100px", width: "100%"}}>
                        <InputSelectField
                            value={localTaskList?.invitedUsers?.map(item => {
                                return {value: item.id, label: nameAlias(item)};
                            })}
                            handleAction={handleInvitedUsers}
                            type={InputSelectType.select}
                            label="Invite users"
                            optionsArray={users ? users.filter((item) =>
                                    !localTaskList?.participants.map(obj => obj.id).includes(item.id))
                                    .filter((item) => item.name !== loggedInUserName)
                                : []}
                            isClearable={true}
                            isMulti={true}
                            handleKeyAction={handleKeyUp}
                        />
                    </div>
                    {editMode &&
                        <div>
                            <div className="" style={{minWidth: "100px", width: "100%"}}>
                                <InputSelectField
                                    value={localTaskList?.participants?.map((item) => {
                                        return {value: item.id, label: item ? nameAlias(item) : ""};
                                    })}
                                    handleAction={handleParticipants}
                                    type={InputSelectType.select}
                                    label="Participants"
                                    optionsArray={taskList.participants}
                                    isClearable={true}
                                    isMulti={true}
                                    handleKeyAction={handleKeyUp}
                                />
                            </div>
                            <div className="" style={{minWidth: "100px", width: "100%"}}>
                                <InputSelectField
                                    value={{label: localTaskList?.owner ? nameAlias(localTaskList.owner) : ""}}
                                    handleAction={(item: DataOptionsUnit) => handleOwner(item.value as number)}
                                    type={InputSelectType.select}
                                    label="Owner"
                                    optionsArray={taskList.participants}
                                    isClearable={false}
                                    isMulti={false}
                                    handleKeyAction={handleKeyUp}
                                    disable={taskList?.owner?.id !== loggedInUser?.id}
                                />
                            </div>
                            <div className="justify-content-center">
                                <div className="table-row-top-line mt-3">
                                    <div className="text-center heading-font2">
                                        Custom fields
                                        <Button className="btn" onClick={addCustomField}>
                                            <i className="fa fa-plus-circle fa-2x color-blue"></i>
                                        </Button>
                                    </div>
                                    {localTaskList?.customFields?.map((item, index) => {
                                        return (
                                            <div key={index} className="mb-3">
                                                <div
                                                    className="d-flex column-gap-2 justify-content-between">
                                                    <div className="w-100">
                                                        <InputSelectField
                                                            value={item.name}
                                                            handleAction={(value: string) => customFieldHandler(value, 'name', index)}
                                                            type={InputSelectType.input}
                                                            label="Name"
                                                            handleKeyAction={handleKeyUp}
                                                        />
                                                    </div>
                                                    <div className="position-relative" style={{width: "30px"}}>
                                                        <div className="position-absolute" style={{top: "63%", transform: "translateY(-50%"}}>
                                                            <Button className="but but-warning but-sm"
                                                                    onClick={() => removeCustomField(index)}>
                                                                <i className="fa fa-trash-o"/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <InputSelectField
                                                    value={{value: item.type, label: item.type}}
                                                    handleAction={(value: DataOptionsUnit) => customFieldHandler(value.value, 'type', index)}
                                                    type={InputSelectType.select}
                                                    optionsArray={Object.values(CustomFieldType).map(item => item.toString())}
                                                    label="Type"
                                                    handleKeyAction={handleKeyUp}
                                                />
                                                {item.type === "list" &&
                                                    <InputSelectField
                                                        value={{label: item.list, value: item.list}}
                                                        handleAction={(value: DataOptionsUnit) => customFieldHandler(value.value, 'list', index)}
                                                        type={InputSelectType.select}
                                                        optionsArray={localTaskList.listParameters?.map(item => item.name)}
                                                        label="List"
                                                        handleKeyAction={handleKeyUp}
                                                    />
                                                }
                                                <InputSelectField
                                                    value={item.filter}
                                                    handleAction={(value: boolean) => customFieldHandler(value, 'filter', index)}
                                                    type={InputSelectType.checkbox}
                                                    label="Filter used"
                                                    handleKeyAction={handleKeyUp}
                                                />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                <div className="table-row-top-line mt-4">
                                    <div className="text-center heading-font2">
                                        List Parameters
                                        <Button className="btn" onClick={addListParameter}>
                                            <i className="fa fa-plus-circle fa-2x color-blue"></i>
                                        </Button>
                                    </div>
                                    {localTaskList?.listParameters?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                 className="d-flex column-gap-2 mb-3">
                                                <div className="w-100">
                                                <InputSelectField
                                                    value={item.name}
                                                    handleAction={(value: string) => listParameterHandler(value, 'name', index)}
                                                    type={InputSelectType.input}
                                                    label={"List " + (index + 1)}
                                                    handleKeyAction={handleKeyUp}
                                                />
                                                </div>
                                                <div className="position-relative" style={{width: "77px"}}>
                                                    <div className="position-absolute gap-2 d-flex"
                                                         style={{top: "63%", transform: "translateY(-50%)"}}>
                                                        <Button className="but but-primary but-sm"
                                                                onClick={() => handleShowListEditor(item, index)}>
                                                            <i className="fa fa-pencil-square-o"/>
                                                        </Button>
                                                        <Button className="but but-warning but-sm"
                                                                onClick={() => deleteListParameter(index)}>
                                                            <i className="fa fa-trash-o"/>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="mt-3">
                    <div className="center-horizontally gap-2">
                        <Button className="but but-primary"
                                onClick={() => saveHandler(localTaskList)}>{editMode ? "Update" : "Add"}</Button>

                        <Button className="but but-light" onClick={cancelHandler}>Cancel</Button>
                    </div>
                </div>
                {editMode &&
                    <div className="position-relative">
                        <div className="position-absolute" style={{right: "20px", top: "-39px"}}>
                            <Button className="but but-warning"
                                    onClick={() => deleteHandler(taskList.id!)}>
                                <i className="fa fa-trash-o" style={{display: "flex"}}></i>
                            </Button>
                        </div>
                    </div>
                }
            </div>
            <TaskListParametersEditor
                showComponent={editableListParameterIndex >= 0}
                list={editableListParameter}
                saveHandler={saveListParameters}
                cancelHandler={() => setEditableListParameterIndex(-1)}/>
        </div>

    )
}

class AddEditTaskListProps {
    showComponent: boolean;
    saveHandler: Function;
    cancelHandler: Function;
    deleteHandler: Function;
    taskList: TaskList;
    users: UserBasic[];
    editMode: boolean;
    loggedInUser: UserBasic | null;
}
