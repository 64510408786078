import {UserBasic} from "./UserBasic";

export class User {
    [key:string] : any
    id: number | null;
    name: string = "";
    description: string = "";
    email: string = "";
    password: string = "";
    alias: string = "";
    status: string = "";
    contacts: UserBasic[] = [];
    sendEmails: boolean = false;
    provider: string = "Local";
}

export const nameAlias = (user: User | UserBasic | null): string => {
    if (!user) return "Error"
    if (user.alias !== "") {
        return user.alias;
    } else {
        return user.name;
    }
}
