import './Button.scss'
import React, {CSSProperties} from "react";
export const Button = (props : ButtonProps) => {

    const { children, onClick, className, rippleColor,
        disabled, style} = props;

    const buttonClicked = (e: React.MouseEvent<HTMLElement>) => {
        const button = e.currentTarget;
        const radius = Math.max(button.clientHeight, button.clientWidth) / 2;
        const circle = document.createElement('span');
        circle.style.left = `${e.clientX - button.offsetLeft - radius}px`;
        circle.style.top = `${e.clientY - button.offsetTop - radius}px`;
        circle.style.width = circle.style.height = `${2 * radius}px`;
        if (rippleColor) {circle.style.backgroundColor = rippleColor;}
        circle.classList.add("ripple");
        const ripple = button.getElementsByClassName("ripple")[0];

        if (ripple) {
            ripple.remove();
        }

        button.appendChild(circle);

        if (onClick) {onClick(e);}
    }

    return (
            <button
                className={"custom-button " + className + (disabled ? " button-disabled" : "")}
                onClick={buttonClicked} disabled={disabled}
                style={style}
            >
                {children}
            </button>
    )
}

class ButtonProps {
    children?: any;
    onClick?: Function;
    className?: string;
    rippleColor?: string;
    disabled?: boolean = false;
    style?: CSSProperties
}