import {Button} from "../forms/Button";

export const PageHeader = (props: PageHeaderProps) => {

    const {
        title, buttonUsed, onClick, disabled,
        refreshUsed, onRefresh, refreshDisabled
    } = props;
    return (
        <div className="horizontal mt-3">
            {refreshUsed &&
                <div className="center-horizontally">
                    <Button className="btn border-0" onClick={onRefresh} disabled={refreshDisabled}>
                        <i className="fa fa-refresh" style={{transform: "scale(1.5)", color: "gray"}}/>
                    </Button>
                </div>
            }
            <div className="title">{title}</div>
            <div className="position-relative">
                <div className="position-absolute" style={{top: "55%", transform: "translateY(-50%)"}}>
                    {buttonUsed &&
                        <div className="center-horizontally ps-2">
                            <Button className="but but-sm but-primary" onClick={onClick} disabled={disabled}>
                                <i className="fa fa-plus" style={{scale: "1.5"}}/>
                            </Button>
                            {/*<Button className="btn border-0" onClick={onClick} disabled={disabled}>*/}
                            {/*    <i className="fa fa-plus-square fa-2x color-blue" style={{}}/>*/}
                            {/*</Button>*/}
                        </div>
                    }
                </div>
            </div>
        </div>

    )
}

class PageHeaderProps {
    title: string;
    buttonUsed?: boolean = false
    onClick?: Function;
    disabled?: boolean;
    refreshUsed?: boolean = false;
    onRefresh?: Function = undefined;
    refreshDisabled?: boolean = false;
}