import "./formstyles.scss";
import {Button} from "./Button";
import {createPortal} from "react-dom";

const ConfirmationWindow = (props: ConfirmationWindowProps) => {

    const {header, body, show, onCancel, onConfirm} = props;

    if (!show) {
        return null;
    }

    return (
        <div>
            {createPortal(
                <div className="modalwindow">
                    <div className="modalcontent">
                        <div className="modalheader">
                            <h5 className="modaltitle">{header}</h5>
                        </div>
                        <div className="modalbody">
                            {body}
                        </div>
                        <div className="modalfooter d-flex gap-2">
                            <Button className="but but-danger" onClick={onConfirm}>Yes</Button>
                            <Button className="but but-primary" onClick={onCancel}>No</Button>
                        </div>
                    </div>
                </div>,
                document.getElementById('root') as HTMLElement
            )}
        </div>
    )
}

class ConfirmationWindowProps {
    header: string;
    body: string;
    show: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export default ConfirmationWindow;