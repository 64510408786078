import {feurl} from "../constants/Constants";
import {useEffect} from "react";
import {PageHeader} from "./PageHeader";
import { motion } from "framer-motion";
import {OpacityAnimation} from "./TaskComp/TaskAnimations";

export const HomePage = () => {

    const url = feurl(window.location.host.split(':')[0]) + "policy";

    useEffect(() => {
        document.querySelectorAll('#homepage h3').forEach(function(h3) {
            h3.classList.add('heading-font2');
            h3.classList.add('fw-bold');
        });
    }, []);

    return (
        <motion.div
            id="homepage"
            {...OpacityAnimation}
        >
            <PageHeader title="Task Simple"/>
            <header>
                <h1 className="heading-font1 center-horizontally mt-3">Welcome to Task Simple: Your Multi-User Todo List Solution</h1>
            </header>
            <div className="center-horizontally">
                <img className="p-5" src="/picture512.png" alt="Task Simple" height="400"/>
            </div>
            <section>
                <h3>Effortlessly Organize and Collaborate</h3>
                <p>Task Simple is a user-friendly, multi-user todo list application designed to simplify your task
                    management and collaboration needs. With the option to sign up using a local account or through
                    Google or Facebook authentication, getting started is as easy as a few clicks. Our commitment to
                    your privacy and usage is outlined in our <a href={url.toString()}>Privacy
                        Policy and Terms of Service</a>.</p>
            </section>

            <section>
                <h3>Secure Sign-Up and Data Handling</h3>
                <ul>
                    <li>Local Account Sign-Up: Requires email confirmation for activation, ensuring the security of
                        your account. A confirmation email will be promptly sent to you upon signing up.</li>
                    <li>Google & Facebook Authentication: Offers immediate account activation, leveraging the verified
                        email addresses from these platforms for your convenience.</li>
                </ul>
            </section>

            <section>
                <h3>Build Your Network</h3>
                <p>Connect with other users by adding them to your contacts. This feature enables seamless
                    collaboration and sharing of task lists among your network.</p>
            </section>

            <section>
                <h3>Task List Creation and Sharing</h3>
                <p>Create multiple task lists on the Task Lists page. Invite contacts to collaborate on your lists.
                    Invitations must be confirmed by recipients to participate. On the Task page, add tasks to a
                    selected list. Specify details like title, responsible individual, description, start and due
                    dates, and time estimations.</p>
            </section>

            <section>
                <h3>Customize Your Task Management</h3>
                <p>Edit task details anytime for flexibility and real-time updates. Create custom fields for tasks
                    in a list, allowing for unique data inputs, including value or list types.</p>
            </section>

            <section>
                <h3>Stay Updated with Announcements</h3>
                <p>Receive notifications related to actions like invitations, task assignments, and completions.
                    Opt for email notifications in your User Settings. Dashboard announcements provide a comprehensive
                    overview, with interactive functionalities for actionable items.</p>
            </section>

            <section>
                <h3>User Settings and Personalization</h3>
                <p>Customize your user alias for recognizable collaboration. If not set, your username (unchangeable)
                    is used. Manage email notifications and account settings, including password changes and account
                    type alterations. Transition seamlessly between local, Google, or Facebook account types based on
                    your preference. Account deletion options are available, with responsible handling of associated
                    data and tasks.</p>
            </section>

            <footer>
                <p>Task Simple is dedicated to providing you with a secure, efficient, and collaborative task management experience. Join us and streamline your productivity today!</p>
            </footer>
        </motion.div>
    )
}