import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {InputSelectField, InputSelectType} from "../forms/InputSelectField";
import {beurl} from "../constants/Constants";
import {useHttp} from "../hooks/http.hook";
import {User} from "../model/User";
import * as Yup from "yup";
import {AnimatePresence, motion} from "framer-motion";
import {OpacityAnimation, ShrinkAnimation} from "./TaskComp/TaskAnimations";
import {emailSchema, passwordConfirmSchema, passwordSchema, yupErrorList} from "../services/ValidationService";
import {Button} from "../forms/Button";

export const SignUpComp = () => {

    /* Constants */
    const url = beurl(window.location.host.split(':')[0]) + "api/login";
    const request = useHttp();

    /* Local states */
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordDpl, setPasswordDpl] = useState("");
    const [email, setEmail] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [nameUsed, setNameUsed] = useState(false);
    const [message, setMessage] = useState("");

    /* Navigate */
    const navigate = useNavigate();

    useEffect(() => {
        setMessage("");
    }, [userName,password,passwordDpl,email,showPassword]);
    const signUp = () => {
        const user: User = {name: userName, email, password} as User;
        request(url + '/signup', 'POST', JSON.stringify(user))
            .then(() => {
                setMessage("");
                navigate("/login")
            })
            .catch(e => {
                // if (e.message.toLowerCase().includes("user")) {
                //     setNameUsed(true);
                // }
                setMessage(e.message);
                console.log(e.message);
            })
    }

    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            handleSubmit();
        }
        if (key === "Escape") {
            // onCancel();
        }
    }

    /* Validation */
    const schema = Yup.object().shape({
        name: Yup.string().required("Name is required").min(4, "Minimum length is 4").max(20, "Maximum length is 20"),
        email: emailSchema,
        password: passwordSchema,
        confirmPassword: passwordConfirmSchema
    });

    const handleSubmit = () => {
        schema.validate({name: userName, email: email, password: password,
            confirmPassword: passwordDpl}, {abortEarly: false})
            .then((data) => {
                signUp();
                setErrors({});
                // setMessage("");
                // console.log("Validation succeeded. ");
                // console.log(data);
            })
            .catch((err) => {
                // setNameUsed(false);
                if (err instanceof Yup.ValidationError) {
                    setErrors(yupErrorList(err));
                }
            })
    }

    return (
        <motion.div
            {...OpacityAnimation}
        >
            <div className="center-all">
                <div className="login-plate mt-5">
                    <div className="heading1">Sign Up</div>
                    <div className="mt-4">
                        <InputSelectField value={userName} handleAction={setUserName} type={InputSelectType.input}
                                          label="User Name" backgroundcolor="#fafafa"
                                          error={nameUsed}
                                          handleKeyAction={handleKeyUp}
                        />
                        <AnimatePresence>
                            {errors.hasOwnProperty("name") &&
                                <ErrorMessage message={errors["name"]}/>
                            }
                        </AnimatePresence>
                    </div>
                    <div className="mt-4">
                        <InputSelectField value={email} handleAction={setEmail} type={InputSelectType.input}
                                          label="E-mail" backgroundcolor="#fafafa"
                                          error={errors.hasOwnProperty("email")}
                                          handleKeyAction={handleKeyUp}
                        />
                        <AnimatePresence>
                            {errors.hasOwnProperty("email") &&
                                <ErrorMessage message={errors["email"]}/>
                            }
                        </AnimatePresence>
                    </div>
                    <div className="mt-4">
                        <InputSelectField value={password} handleAction={setPassword}
                                          type={showPassword ? InputSelectType.input : InputSelectType.password}
                                          label="Password" backgroundcolor="#fafafa"
                                          error={errors.hasOwnProperty("password")}
                                          handleKeyAction={handleKeyUp}
                        />
                    </div>
                    <AnimatePresence>
                        {errors.hasOwnProperty("password") &&
                            <ErrorMessage message={errors["password"]}/>
                        }
                    </AnimatePresence>
                    <div className="mt-4">
                        <InputSelectField value={passwordDpl} handleAction={setPasswordDpl}
                                          type={showPassword ? InputSelectType.input : InputSelectType.password}
                                          label="Repeat password" backgroundcolor="#fafafa"
                                          error={errors.hasOwnProperty("confirmPassword")}
                                          handleKeyAction={handleKeyUp}
                        />
                    </div>
                    <AnimatePresence>
                        {errors.hasOwnProperty("confirmPassword") &&
                            <ErrorMessage message={errors["confirmPassword"]}/>
                        }
                    </AnimatePresence>
                    <div className="center-all mt-2">
                        <InputSelectField value={showPassword} handleAction={setShowPassword}
                                          type={InputSelectType.checkbox}
                                          label="- show password" handleKeyAction={undefined}/>
                    </div>
                    <div className="mt-1" style={{minHeight: "1.5rem"}}>
                        <AnimatePresence>
                            {message !== "" &&
                                <motion.div
                                    {...ShrinkAnimation}
                                    className="center-horizontally error-text overflow-hidden"
                                >
                                    {message}
                                </motion.div>
                            }
                        </AnimatePresence>
                    </div>
                    <div className="center-all mt-1 d-flex gap-2">
                        <Button
                            className="but but-primary but-sm"
                            onClick={handleSubmit}
                            style={{width: "80px"}}
                        >
                            Sign Up
                        </Button>
                        <Button
                            className="but but-warning but-sm"
                            onClick={() => navigate("/login")}
                            style={{width: "80px"}}
                        >
                            Cancel
                            
                        </Button>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export const ErrorMessage = (props: ErrorMessageProps) => {

    const {message, top, left} = props;

    return (
        <div className="position-relative">
            <motion.div
                {...OpacityAnimation}
                className="error-text-sm position-absolute"
                style={{top: top, left: left}}
            >
                {message}
            </motion.div>
        </div>
    )
}

class ErrorMessageProps {
    message: string;
    top?: string = "0px";
    left?: string = "0px";
}