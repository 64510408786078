import React from "react";
import {beurl} from "../../constants/Constants";

export const PolicyAndService = () => {

    return (
        <div>
            <h2 className="heading-font1 mb-3">Detailed Privacy and Usage Information</h2>

            <ol className="gap-5">
                <li className="heading-font2 fw-bold">Email Address Usage:</li>
                <ul>
                    <li>We require your valid email address primarily for two purposes:
                        <ul>
                            <li>Announcements: To send you announcements, but only if you
                                opt-in via your settings. By default, this function is turned off.
                            </li>
                            <li>Password Recovery: To assist with password restoration in
                                case
                                you forget your password.
                            </li>
                        </ul>
                    </li>
                    <li>Privacy Assurance: Your email address is not shared with third
                        parties
                        and is strictly used for the aforementioned purposes only.
                    </li>
                </ul>

                <li className="heading-font2 fw-bold">Social Media Logins:</li>
                <ul>
                    <li>If you choose to log in via Google or Facebook, we will access your public name, email and ID.
                    </li>
                    <li>Usage of Public Name: This name is used solely as your alias within
                        the
                        application, allowing you to interact with other users.
                    </li>
                    <li>Alias Modification: You have the option to change this alias
                        anytime
                        within the application.
                    </li>
                    <li>Usage of ID: The application retrieves the user ID from the authentication provider and
                        utilizes it for user verification, particularly in instances where the user's primary email
                        has been altered.
                    </li>
                </ul>

                <li className="heading-font2 fw-bold">Cookie Usage:</li>
                <ul>
                    <li>Cookies play a vital role in our application for:
                        <ul>
                            <li>Authentication: To verify and maintain your signed-in
                                status.
                            </li>
                            <li>Enhanced Usability: For example, to remember your
                                preference
                                about not showing this notification again if you accept our policy.
                            </li>
                        </ul>
                    </li>
                    <li>Policy Acceptance: Accepting our policy can be done in two ways -
                        either directly on the Login page or implicitly by starting to use the application.
                    </li>
                </ul>

                <li className="heading-font2 fw-bold">Account Deletion and Data Retention:</li>
                <ul>
                    <li>You have the right to delete your account at any time.</li>
                    <li>Data Deletion: Upon account deletion, all your data will be
                        permanently
                        erased from our system without the possibility of restoration.
                    </li>
                </ul>
            </ol>

            <h2 className="heading-font1 mb-3">Terms of Service</h2>

            <ol>
                <li className="heading-font2 fw-bold">Service Provision:</li>
                <ul>
                    <li>The service is provided "as is" and free of charge.</li>
                    <li>We reserve the right to modify, suspend, or discontinue the service at any time,
                        without notice, and without liability.
                    </li>
                </ul>

                <li className="heading-font2 fw-bold">No Guarantees:</li>
                <ul>
                    <li>We make no guarantees or promises regarding the availability, reliability,
                        functionality, or suitability of the service for any specific purposes.
                    </li>
                    <li>Users acknowledge that they use the service at their own risk.</li>
                </ul>

                <li className="heading-font2 fw-bold">Limitation of Liability:</li>
                <ul>
                    <li>To the fullest extent permitted by law, we disclaim all liability for any
                        claims,
                        damages, losses, or other liabilities arising from or related to your use or
                        inability to use the service.
                    </li>
                    <li>This includes, but is not limited to, direct, indirect, incidental, punitive,
                        and
                        consequential damages, unless otherwise specified in writing.
                    </li>
                </ul>

                <li className="heading-font2 fw-bold">No Warranty:</li>
                <ul>
                    <li>We provide the service without warranties of any kind, either express or
                        implied,
                        including but not limited to implied warranties of merchantability, fitness for
                        a
                        particular purpose, or non-infringement.
                    </li>
                </ul>

                <li className="heading-font2 fw-bold">User Responsibilities:</li>
                <ul>
                    <li>Users are responsible for any activities that occur under their account and for
                        complying with all applicable laws and regulations in their use of the service.
                    </li>
                    <li>Users agree not to use the service for any illegal or unauthorized purpose.</li>
                </ul>

                <li className="heading-font2 fw-bold">Indemnification:</li>
                <ul>
                    <li>Users agree to indemnify and hold harmless the service, its owners, affiliates,
                        and
                        their respective officers, directors, agents, and employees, from any claim or
                        demand, including reasonable attorneys’ fees, made by any third party due to or
                        arising out of their breach of these Terms of Service or their violation of any
                        law
                        or the rights of a third party.
                    </li>
                </ul>

                <li className="heading-font2 fw-bold">Governing Law:</li>
                <ul>
                    <li>These Terms of Service shall be governed and construed in accordance with the
                        laws
                        of the jurisdiction in which the service operates, without regard to its
                        conflict of
                        law provisions.
                    </li>
                </ul>

                <li className="heading-font2 fw-bold">Changes to Terms:</li>
                <ul>
                    <li>We reserve the right to modify or replace these Terms of Service at any time. It
                        is
                        the user's responsibility to review these Terms periodically for changes.
                    </li>
                </ul>
            </ol>

            {/*<p>Feel free to accept our policy as outlined or contact us if you have any concerns or need*/}
            {/*    further*/}
            {/*    clarification.*/}
            {/*</p>*/}
        </div>
    )
}