import {TaskListShort} from "./TaskListShort";
import {UserBasic} from "./UserBasic";

export class Task {
    [key: string]: any;
    id: number | null | undefined = undefined;
    name: string = "";
    description: string = "";
    priority: number = 0;
    responsible: UserBasic[] = [];
    startTime: string = "";
    endTime: string = "";
    taskDuration: number = 0;
    taskList: TaskListShort | null;
    owner: UserBasic | null = null;
    completed: boolean = false;
    customFields: Map<string, string> = new Map<string, string>();
    completePercentage: number = 0;
    spentHours: number = 0;
}
