import {Container, Nav, Navbar} from "react-bootstrap";

import {useDispatch, useSelector} from "react-redux";
import {AuthUser, taskFilterSlice, userSlice, userSettingsSlice, sorterSlice} from "../reducers";
import React, {useEffect, useState} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {UserBasic} from "../model/UserBasic";
import {checkAuthentication, logoutUser, requestUserSettings, saveUserSettings} from "../services/UserService";
import {mapFromJSON} from "../services/JsonService";
import {UserSettings} from "../model/UserSettings";

const NavBar = () => {

    /* Constants */

    /* Redux */
    const dispatch = useDispatch();
    const loggedInUser: UserBasic | null = (useSelector<AuthUser>(state => state.user) as AuthUser).user
    const {setUser, removeUser} = userSlice.actions;
    const {setSettings, removeSetting} = userSettingsSlice.actions;
    const {addTaskFilter, setTaskFilter, resetTaskFilter} = taskFilterSlice.actions;
    const {setSorters} = sorterSlice.actions;
    const userSettings = useSelector<any>(state => state.usersettings) as UserSettings;

    /* States */
    const [localUser, setLocalUser] = useState<UserBasic | null>(null);
    const [expanded, setExpanded] = useState(false);
    const [delayedExecution, setDelayedExecution] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    // const test = () => {
    //     console.log(username);
    // }

    /* Hooks */
    useEffect(() => {
        isAuthenticated();
        if (loggedInUser) {
            getUserSettings();
        }
        const timer = setTimeout(() => {
            setDelayedExecution(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {
        if (loggedInUser) {
            getUserSettings();
        }
    }, [loggedInUser]);

    useEffect(() => {
        if (userSettings && delayedExecution) {
            saveUserSettings(userSettings.settings);
        }
    }, [userSettings]);

    /* Functions */

    const getUserSettings = () => {
        requestUserSettings()
            .then(settings => {
                if (settings) {
                    if (settings.size > 0 && settings.has("filters")) {
                        // dispatch(addTaskFilter({key: "tasklist", value: settings.get("tasklist")}));
                        dispatch(setTaskFilter(mapFromJSON("", settings.get("filters"))));
                    }
                    if (settings.size > 0 && settings.has("sorters")) {
                        // dispatch(addTaskFilter({key: "tasklist", value: settings.get("tasklist")}));
                        dispatch(setSorters(mapFromJSON("", settings.get("sorters"))));
                    }
                }
            })
    }

    const isAuthenticated = () => {
        checkAuthentication()
            .then(data => {
                // console.log(data);
                if (data) {
                    setLocalUser(data);
                    dispatch(setUser(data));
                }
            })
            .catch(e => {
                // console.error("User is not authenticated.");
                setLocalUser(null);
                logout();
            })
    }

    const logout = () => {
        dispatch(removeUser(""));
        dispatch(resetTaskFilter(""));
        dispatch(removeSetting(""));
        logoutUser();
        if (!(location.pathname.includes("/confirmation")
            || location.pathname.includes("/newpassword")
            || location.pathname.includes("/policy")
            || location.pathname.includes("/login")
            || location.pathname.includes("/expired")
        )) {
            navigate("/");
        }
    }

    const handleLogout = () => {
        logout();
        setExpanded(false);
    }

    const handleExpanded = () => {
        setExpanded(false);
    }

    return (
        <Navbar expanded={expanded} bg="light" expand="md" sticky="top" style={{height: "50px"}}
                className="navbar-style">
            <Container>
                <Navbar.Brand className="navbar-brand-style" onClick={() => navigate("/")} style={{cursor: "pointer"}}>
                    <div className="d-flex gap-2">
                        <div className="">
                            <img className="" src="/logo128.png" alt="Task Simple" height="35"/>
                        </div>
                        <div className="d-grid align-items-center">
                            <div>
                                Task Simple
                                <span style={{fontSize: "0.6rem", fontStyle: "italic"}}>
                                    {" " + (process.env.REACT_APP_VERSION || process.env.VERSION)}
                                </span>
                            </div>
                        </div>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}>
                    <i className="fa fa-th"></i>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" aria-controls="responsive-navbar-nav">
                    <Nav className="ms-auto bg-light">
                        {(!loggedInUser) ?
                            <NavLink to="/" className="nav-link" onClick={handleExpanded}>Home</NavLink> : null}
                        {(loggedInUser && loggedInUser.status === "Active") ?
                            <NavLink to="/tasks" className="nav-link" onClick={handleExpanded}>Tasks</NavLink> : null}
                        {(loggedInUser && loggedInUser.status === "Active") ?
                            <NavLink to="/tasklists" className="nav-link" onClick={handleExpanded}>Task
                                Lists</NavLink> : null}
                        {(loggedInUser && loggedInUser.status === "Active") ?
                            <NavLink to="/dashboard" className="nav-link"
                                     onClick={handleExpanded}>Dashboard</NavLink> : null}
                        {loggedInUser ? <NavLink to="/settings" className="nav-link"
                                                 onClick={handleExpanded}>Settings</NavLink> : null}
                        {loggedInUser ? null :
                            <NavLink to="/login" className="nav-link" onClick={handleExpanded}>
                                Login
                            </NavLink>}
                        {loggedInUser ? <NavLink to="/login" className="nav-link" onClick={handleLogout}>
                            Log out ({loggedInUser.name})
                        </NavLink> : null}
                        <NavLink to="/policy" className="nav-link" onClick={handleExpanded}>
                            <svg className="nav-svg" width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 1L3 6v6c0 5 5 10 9 11s9-6 9-11V6l-9-5z" fill="none" strokeWidth="1"/>
                                <path d="M7 7H17" strokeWidth="1" strokeLinecap="round"/>
                                <path d="M7 9H14" strokeWidth="1" strokeLinecap="round"/>
                                <path d="M7 11H12" strokeWidth="1" strokeLinecap="round"/>
                                <path d="M8 13L12 17L18 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            {/*<button onClick={test}>Test</button>*/}
            {/*<Button className="but but-primary" onClick={() => console.log(userSettings)}>Test</Button>*/}
        </Navbar>
    )
}

export default NavBar;
