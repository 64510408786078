import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useHttp} from "../../hooks/http.hook";
import {beurl} from "../../constants/Constants";
import {PageHeader} from "../PageHeader";
import {Button} from "../../forms/Button";
import {checkAuthentication} from "../../services/UserService";
import {AnimatePresence, motion} from "framer-motion";
import {OpacityAnimation} from "../TaskComp/TaskAnimations";
import {useDispatch, useSelector} from "react-redux";
import {AuthUser, userSlice} from "../../reducers";
import {UserBasic} from "../../model/UserBasic";

export const ChangeTypeComp = () => {

    /* Constants */
    const request = useHttp();
    const url = beurl(window.location.host.split(':')[0]) + "api/current/";

    const type = useParams()

    /* Local states */
    const [ message, setMessage] = useState("");
    const [loginType, setLoginType] = useState("Unknown")

    /* Redux */
    const dispatch = useDispatch();
    const {setUser} = userSlice.actions;
    const loggedInUser: UserBasic | null = (useSelector<AuthUser>(state => state.user) as AuthUser).user

    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setLoginType(params.get('type') as string);
    }, []);
    const changeAccountToGoogle = () => {
        // console.log("Yes")
        request(url + "changeacctype", "POST")
            .then(() => {
                checkAuthentication()
                    .then(data => {
                        // console.log(data);
                        if (data) {
                            dispatch(setUser(data));
                        }
                        navigate("/tasks");
                    })
                    .catch(e => {
                        console.error(e.message);
                    })
            })
            .catch((e) => {
                setMessage(e.message);
            })
    }

    const rejectTypeChange = () => {
        // console.log("No")
        // logoutUser();
        navigate("/settings");
    }

    useEffect(() => {
    }, []);


    return (
        <div>
            <PageHeader title={"Change account type"} buttonUsed={false}/>
            <div className="">
                <div className="center-horizontally mt-3">
                    {`${loggedInUser?.provider} user with this e-mail already exists. Do you want to change 
                    account type to ${loginType} and log in with ${loginType} authentication in the future?`}
                </div>
                <div className="" style={{height: "1.5rem"}}>
                    <AnimatePresence>
                        {message !== "" &&
                            <motion.div
                                {...OpacityAnimation}
                                className="center-horizontally error-text overflow-hidden"
                            >
                                {message}
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
                <div className="center-horizontally gap-2 mt-1">
                    <Button className="but but-primary" onClick={changeAccountToGoogle}>
                        Yes
                    </Button>
                    <Button className="but but-warning" onClick={rejectTypeChange}>
                        No
                    </Button>
                </div>
            </div>
        </div>
    )
}