import {motion} from "framer-motion";
import {SpringAnimation} from "../components/TaskComp/TaskAnimations";

export const SimpleCell = (props: SimpleRowProps) => {

    const {children, style, className} = props;

    return (
        <motion.div
            {...SpringAnimation}
            style={style}
        >
            <div className={className}>
                {children}
            </div>
        </motion.div>
    )
}

class SimpleRowProps {
    children: any;
    // value: string;
    style: any;
    className?: string = "";
}