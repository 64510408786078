import * as Yup from "yup";

export const usernameSchema = Yup.string()
    .min(4, "Minimum 4 symbols")
    .max(100, "Maximum length is 100 symbols")
    .required("User name required");
export const emailSchema = Yup.string().email("Incorrect e-mail").required("Proper e-mail required");
export const passwordSchema = Yup.string().required("Password required")
    .min(8, "Password must include 8 symbols at least");

export const passwordConfirmSchema = Yup.string().required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords must match");

export const yupErrorList = (err: Yup.ValidationError): {} => {
    return err.inner.reduce((acc: { [key: string]: string }, curr) => {
        if (curr.path && !acc[curr.path]) {
            acc[curr.path] = curr.message;
        }
        return acc;
    }, {});
}