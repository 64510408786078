import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useHttp} from "../../hooks/http.hook";
import {beurl} from "../../constants/Constants";
import {PageHeader} from "../PageHeader";

export const EmailConfirmationComp = () => {

    /* Constants */
    const request = useHttp();
    const url = beurl(window.location.host.split(':')[0]) + "api/login/confirmation";

    /* Local states */
    const [confirmationStatus, setConfirmationStatus] = useState("Confirming e-mail address...")
    const {key} = useParams();

    useEffect(() => {
        console.log(key);
        sendConfirmation(key);
    }, []);

    const sendConfirmation = (key: string | undefined) => {
        if (key) {
            request(url + "/" + key)
                .then(response => {
                    console.log(response);
                    setConfirmationStatus(response.response);
                })
                .catch(e => {
                    console.log("Failed to confirm e-mail.");
                })
        }
    }

    return (
        <div>
            <PageHeader title={"E-mail confirmation"} buttonUsed={false}/>
            <div className="center-horizontally mt-3 heading-font2">
                {confirmationStatus}
            </div>
        </div>
    )
}