export const mapToJSON = (key: string, value: any) => {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: [...value.entries()]
        };
    } else {
        return value;
    }
}

export const mapFromJSON = (key: string, value: any) => {
    // console.log(value);
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value.map((item: any) => [item[0], item[1]]));
        }
    }
    return value;
}