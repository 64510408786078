import {PolicyAndService} from "./PolicyAndService";
import {PageHeader} from "../PageHeader";
import { motion } from "framer-motion";
import {OpacityAnimation} from "../TaskComp/TaskAnimations";

export const PolicyAndServicePage = () => {
    return (
        <motion.div
            {...OpacityAnimation}
        >
            <PageHeader title="Privacy Policy and Terms of Service"/>
            <div className="mt-3"></div>
            <PolicyAndService/>
        </motion.div>
    )
}