import {useEffect, useRef, useState} from "react";
import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {Button} from "../../forms/Button";
import {AnimatePresence, motion} from "framer-motion";
import {OpacityAnimation} from "../TaskComp/TaskAnimations";

export const InviteUserComp = (props: InviteUserCompProps) => {
    /* Local constants*/
    const {sendInvitation, onCancel, errorMessage} = props;

    /* Local Refs */
    const startRef = useRef<HTMLInputElement>(null);

    /* Local states */
    const [invitedUser, setInvitedUser] = useState("");

    useEffect(() => {
        if (startRef && startRef.current) {
            // setTimeout(() => {
            startRef.current!.focus();
            // }, 200)
        }
    }, []);


    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            sendInvitation(invitedUser);
        }
        if (key === "Escape") {
            onCancel();
        }
    }

    return (
        <div className="modalwindow">
            <div className="modalcontent">
                <div className="modalheader">
                    <h3 className="modaltitle heading-font1">
                        Invite user
                    </h3>
                </div>
                <div className="center-horizontally">
                    Insert correct user name
                </div>
                <div className="mt-4">
                    <InputSelectField value={invitedUser} handleAction={setInvitedUser}
                                      type={InputSelectType.input}
                                      label="User name" backgroundcolor="#fafafa"
                                      ref={startRef}
                                      handleKeyAction={handleKeyUp}
                    />
                </div>
                <div style={{height: "1.5rem"}}>
                    <AnimatePresence>
                        {(errorMessage !== "") &&
                            <motion.div
                                {...OpacityAnimation}
                                className="error-text"
                            >
                                {errorMessage}
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
                <div className="d-flex center-all mt-3 gap-2">
                    <div>
                        <Button className="but but-primary but-sm"
                                onClick={() => sendInvitation(invitedUser)}>
                            Send request
                        </Button>
                    </div>
                    <div>
                        <Button className="but but-warning but-sm" onClick={() => onCancel()}>Cancel
                        </Button>
                    </div>
                </div>

            </div>
        </div>

    )
}

class InviteUserCompProps {
    sendInvitation: Function;
    onCancel: Function;
    errorMessage: string;
}