import React, {Fragment, useEffect, useRef, useState} from "react";
import {Button} from "../../forms/Button";
import {CollapsibleRow} from "../../forms/CollapsibleRow";
import {TaskListUserSettings} from "../../model/TaskListUserSettings";
import {Task} from "../../model/Task";
import {AnimatePresence, motion} from "framer-motion";
import {TaskList} from "../../model/TaskList";
import {ShrinkAnimation, SpringAnimation} from "./TaskAnimations";
import {CompletedCell, style, TableCell} from "./TaskColumnInit";
import {defaultColumnSettings, TaskColumnSettings} from "./TaskColumnSettings";
import {Exception} from "sass";
import {nameAlias} from "../../model/User";
import {applyLocalFilteringForCustomFields, prepareFiltersUrl} from "./TaskFilterServices";
import ConfirmationWindow from "../../forms/ConfirmationWindow";
import {createPortal} from "react-dom";
import {TaskAddEditComp} from "./TaskAddEditComp";
import {beurl} from "../../constants/Constants";
import {useHttp} from "../../hooks/http.hook";
import {useJSON} from "../../hooks/json.hook";
import {TaskListShort} from "../../model/TaskListShort";
import {useDispatch, useSelector} from "react-redux";
import {SorterData, sorterSlice, userSettingsSlice} from "../../reducers";
import {checkAuthentication, logout, saveUserSettings} from "../../services/UserService";
import {UserSettings} from "../../model/UserSettings";
import Loader from "../Loader";
import {CollapsibleRow2} from "../../forms/CollapsibleRow2";
import {useNavigate} from "react-router-dom";

export const TasksTableComp = (props: TasksTableProps) => {

    const {
        taskListUserSettings, showAllDescriptions,
        selectedTaskList, taskListFilter,
        customFilter, externalGetTasks, externalAddNewTask
    } = props;

    /* Constants */
    const taskUrl = beurl(window.location.host.split(':')[0]) + "api/task";
    const request = useHttp();
    const {mapToJSON, mapFromJSON} = useJSON();

    /* Redux */
    const dispatch = useDispatch();
    const {addSorter, removeSorter} = sorterSlice.actions;
    const {addSetting, removeSetting} = userSettingsSlice.actions;
    const sorter = (useSelector<SorterData>(state => state.sorter) as SorterData).sorter.get("task");
    const sorterMap = (useSelector<SorterData>(state => state.sorter) as SorterData).sorter;
    const userSettings = useSelector<any>(state => state.usersettings) as UserSettings;

    /* Local states */
    const [tableWidth, setTableWidth] = useState(0);
    const [sortableTable, setSortableTable] = useState<VisualTask[] | null>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(-1);
    const [newTask, setNewTask] = useState<Task>(new Task());
    const [showAddTask, setShowAddTask] = useState(false);
    const [showDescription, setShowDescription] = useState(-1);
    const [delayedExecution, setDelayedExecution] = useState(false);
    // const [loaded, setLoaded] = useState(false);

    /* Local references */
    const rowRef = useRef<HTMLDivElement | null>(null);
    const hasMounted1 = useRef(false);
    const hasMounted2 = useRef(false);
    const hasMounted3 = useRef(false);
    const hasMounted4 = useRef(false);

    const navigate = useNavigate();

    const sorterValues = (): Map<string, string> => {
        const srtVls = new Map<string, string>();
        srtVls.set("title", "name");
        srtVls.set("start", "startTime");
        srtVls.set("end", "endTime");
        srtVls.set("duration", "taskDuration");

        return srtVls;
    }

    type rowItem = {
        [key: string]: any;
    }

    class VisualTask {
        task: Task;
        visual: rowItem = {};
        classes: rowItem = {};
    }

    /* Hooks */
    useEffect(() => {
        handleResize();

        const timer = setTimeout(() => {
            setDelayedExecution(true);
        }, 500);

        window.addEventListener('resize', handleResize);
        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        if (hasMounted2.current) {
            if (taskListFilter && delayedExecution) {
                getTasks();
            }
        } else {
            hasMounted2.current = true;
        }
        // console.log(taskListFilter);
    }, [taskListFilter, customFilter]);

    useEffect(() => {
        if (delayedExecution) {
            getTasks();
        }
    }, [delayedExecution]);

    useEffect(() => {
        if (hasMounted1.current) {
            if (tasks.length > 0) {
                let userSettings: Map<string, TaskColumnSettings>;
                if ((selectedTaskList && tasks[0] && selectedTaskList.id !== tasks[0].taskList?.id)) {
                    userSettings = new Map(defaultColumnSettings());
                } else {
                    userSettings = new Map(taskListUserSettings.settings);
                }
                // console.log(userSettings);
                const rows: VisualTask[] = [];
                const sorterVal = sorterValues();
                const localTasks = [...tasks];
                localTasks.sort((a, b) => (a.id && b.id) ? (a.id - b.id) : 0)
                localTasks.forEach((item, index) => {
                    const visualTask: VisualTask = new VisualTask();
                    visualTask.task = item;
                    [...userSettings.entries()].forEach((col, idx) => {
                        const prop = sorterVal.has(col[0]) ? sorterVal.get(col[0])!! : col[0];
                        switch (prop) {
                            case "schedule" : {
                                visualTask.visual.schedule = [item.startTime, item.endTime];
                                visualTask.classes.schedule = ["starting-date", (Date.parse(visualTask.task.endTime) < Date.now()) ? "end-date-exceeded" : "end-date"];
                                break;
                            }
                            case "endTime" : {
                                visualTask.visual.end = item.endTime;
                                visualTask.classes.end = (Date.parse(visualTask.task.endTime) < Date.now()) ? "end-date-exceeded" : "end-date";
                                break;
                            }
                            case "taskDuration" : {
                                visualTask.visual.duration = item.taskDuration;
                                break;
                            }
                            case "responsible" : {
                                visualTask.visual.responsible = item.responsible.map(item => nameAlias(item));
                                break;
                            }
                            case "owner" : {
                                visualTask.visual.owner = item.owner ? nameAlias(item.owner) : "";
                                break;
                            }
                            default: {
                                if (item.customFields.has(prop)) {
                                    visualTask.visual[col[0]] = item.customFields.get(prop)!!;
                                } else {
                                    visualTask.visual[col[0]] = item[prop] ? item[prop] : "";
                                }
                            }
                        }
                    })
                    rows.push(visualTask);
                })
                setSortableTable([...rows].sort((a, b) => sortRows(a, b)));
            } else {
                setSortableTable([]);
            }
        } else {
            hasMounted1.current = true;
        }
    }, [tasks, taskListUserSettings]);

    useEffect(() => {
        handleResize();
    }, [taskListUserSettings]);

    useEffect(() => {
        if (showAddTask) {
            setNewTask({...newTask, taskList: selectedTaskList as TaskListShort})
        }
    }, [showAddTask]);

    useEffect(() => {
        if (sortableTable) {
            setSortableTable([...sortableTable].sort((a, b) => sortRows(a, b)));
        }

        const settings = new Map(userSettings.settings);
        settings.set("sorters", mapToJSON("", sorterMap));
        dispatch(addSetting({key: "sorters", value: mapToJSON("", sorterMap)}));

    }, [sorter]);

    useEffect(() => {
        if (hasMounted3.current) {
            getTasks();
        } else {
            hasMounted3.current = true;
        }
    }, [externalGetTasks]);

    useEffect(() => {
        if (hasMounted4.current) {
            setShowAddTask(true);
        } else {
            hasMounted4.current = true;
        }
    }, [externalAddNewTask]);

    useEffect(() => {
        if (!showAllDescriptions) {
            setShowDescription(-1);
        }
    }, [showAllDescriptions]);

    useEffect(() => {
        if (delayedExecution) {
            dispatch(addSorter({key: "task", value: {value: "id", ascending: true}}));
        }
    }, [selectedTaskList]);

    /* Get, add, delete tasks */
    const getTasks = () => {
        let urlWithItems: string = prepareFiltersUrl(taskUrl + "/task", taskListFilter, customFilter);
        request(urlWithItems)
            .then(data => {
                    // console.log(tasks.length);
                    setTasks(applyLocalFilteringForCustomFields(data, customFilter))
                }
            )
            .catch((e) => {
                    console.error(e.message);
                    logout();
                    navigate("/expired");
                }
            )
    }

    const addNewOrEditTask = (task: Task) => {
        request(taskUrl + "/task", 'POST', JSON.stringify({
            ...task,
            customFields: JSON.stringify(task.customFields, mapToJSON)
        }))
            .catch((e) => console.error(e.message))
            .finally(getTasks);
        setEditMode(false);
    }

    const deleteTask = () => {
        if (taskToDelete > -1) {
            request(taskUrl + '/task/' + taskToDelete, 'DELETE')
                .then(() => setNewTask(new Task()))
                .catch((e) => console.error(e.message))
                .finally(getTasks);
        }
        setTaskToDelete(-1);
        setShowAddTask(false);
    }

    const completeTask = (id: number) => {
        request(taskUrl + "/taskcomplete/" + id, 'PUT')
            .catch((e) => console.error(e.message))
            .finally(getTasks)
    }

    const handleTaskAdd = (task: Task) => {
        addNewOrEditTask(task);
        setShowAddTask(false);
        setNewTask(new Task());
    }

    const handleEditTask = (task: Task) => {
        checkAuthentication()
            .then((_) => {
                setNewTask(task);
                setEditMode(true);
                setShowAddTask(true);
            })
            .catch(e => {
                logout();
                navigate("/expired");
            })
    }

    const cancelAddEdit = () => {
        setShowAddTask(false);
        setEditMode(false);
        setNewTask(new Task());
    }

    /* Local function */
    const toggleDescription = (index: number) => {
        if (showDescription === index) {
            setShowDescription(-1);
        } else {
            setShowDescription(index);
        }
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        const elems = rowRef.current?.children;
        if (elems) {
            // console.log([...elems].map(item => (item as HTMLElement).style.minWidth));
            let width = 0;
            [...elems].forEach(item => {
                const style = window.getComputedStyle(item);
                width += parseInt(style.minWidth);
            })
            // console.log(width);
            setTableWidth(width);
        }
    }

    const handleSorting = (value: string) => {
        if (value === sorter?.value && !sorter?.ascending) {
            dispatch(addSorter({key: "task", value: {value: "id", ascending: true}}));
        } else if (value === sorter?.value) {
            dispatch(addSorter({key: "task", value: {value, ascending: !sorter.ascending}}));
        } else {
            dispatch(addSorter({key: "task", value: {value, ascending: true}}));
        }
    }


    const sortRows = (a: VisualTask, b: VisualTask): number => {
        // console.log(sorter);
        if (sorter && sorter.value === "id") {
            return (a.task.id && b.task.id) ? (a.task.id - b.task.id) : 0;
        }
        if (sorter) {
            try {
                return ((a.visual[sorter.value].toLowerCase() > b.visual[sorter.value].toLowerCase()) ? (sorter.ascending ? 1 : -1)
                    : (a.visual[sorter.value].toLowerCase() < b.visual[sorter.value].toLowerCase()) ? (sorter.ascending ? -1 : 1) : 0);
            } catch (e: any) {
                return ((a.visual[sorter.value] > b.visual[sorter.value]) ? (sorter.ascending ? 1 : -1)
                    : (a.visual[sorter.value] < b.visual[sorter.value]) ? (sorter.ascending ? -1 : 1) : 0);
            }
        }
        return 0;
    }

    if (!delayedExecution) {
        return (
            <Loader/>
        )
    }

    return (
        <div className="table-container">
            {/*<Button className="but but-sm" onClick={() => console.log(tableWidth)}>Test</Button>*/}
            <div
                className="collapsible-x-scroll"
                style={windowWidth > 900 ? {fontSize: "1rem"} : windowWidth < 300 ? {fontSize: "0.5rem"}
                    : {fontSize: (((windowWidth - 600) / 600) * 0.3 + 0.7) + "rem"}}
            >
                <div>
                    {/* Header */}
                    <div className="table-header table-row" style={{minWidth: tableWidth ? tableWidth : "0"}}
                         ref={rowRef}>
                        <div style={{minWidth: "25px", width: "25px", paddingLeft: "5px"}}>#</div>
                        <AnimatePresence>
                            {[...taskListUserSettings.settings.entries()]
                                .sort((a, b) => {
                                    return a[1].columnPosition - b[1].columnPosition;
                                })
                                .map((item, index) => {
                                        return (
                                            <motion.div
                                                {...SpringAnimation}
                                                key={generateHash(item[0])}
                                                className={"column-visible" + (item[1].visible ? "" : " column-hidden")}
                                                style={{
                                                    width: item[1].width,
                                                    minWidth: item[1].minWidth,
                                                    textAlign: item[1].align,
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => handleSorting(item[0])}
                                            >
                                                <div className="overflow-hidden me-2">
                                                    {item[1].title}
                                                    {sorter?.value === item[0] && sorter.ascending &&
                                                        <i className="fa fa-angle-down"></i>}
                                                    {sorter?.value === item[0] && !sorter.ascending &&
                                                        <i className="fa fa-angle-up"></i>}
                                                </div>
                                            </motion.div>

                                        )
                                    }
                                )}
                        </AnimatePresence>
                        <div style={{minWidth: "30px", width: "30px"}}></div>
                    </div>

                    <AnimatePresence>
                        {/* Rows */}
                        {sortableTable ? sortableTable
                            .map((item, index) => {
                                return (
                                    <Fragment key={item.task.id}>
                                        <div
                                            key={item.task.id}
                                            {...ShrinkAnimation}
                                            className={"table-row-top-line table-row" + (index % 2 === 1 ? " task-even" : "")}
                                            style={{
                                                minWidth: tableWidth ? tableWidth : "0",
                                                cursor: "pointer",
                                                // minHeight: "1.5rem",
                                            }}
                                        >
                                            <div style={{minWidth: "25px", width: "25px", paddingLeft: "5px",
                                                fontSize: index + 1 > 99 ? "0.65rem" : index + 1 > 9 ? "0.9rem" : "1rem",}}
                                                 className="center-vertically">
                                                {index + 1}
                                            </div>
                                            {[Object.entries(item.visual)].map((row, idx) => {
                                                return row
                                                    .sort((a, b) => {
                                                        return ((taskListUserSettings.settings.get(a[0]) && taskListUserSettings.settings.get(b[0])) ?
                                                            (taskListUserSettings.settings.get(a[0])!!.columnPosition
                                                                - taskListUserSettings.settings.get(b[0])!!.columnPosition) : 0);
                                                    })
                                                    .map((cell, ix) => {
                                                        return (
                                                            <Fragment
                                                                // key={taskListUserSettings.settings.get(cell[0])?.id}
                                                                key={ix}
                                                            >
                                                                {
                                                                    (cell[0] === 'completed') ?
                                                                        <CompletedCell
                                                                            visible={taskListUserSettings.settings.get(cell[0])?.visible!!}
                                                                            value={cell[1]}
                                                                            style={style(taskListUserSettings.settings.get(cell[0])!!)}
                                                                            id={item.task.id!!}
                                                                            onClick={() => completeTask(item.task.id!!)}
                                                                        /> :
                                                                        <TableCell
                                                                            visible={taskListUserSettings.settings.get(cell[0])?.visible!!}
                                                                            value={cell[1] ? ((cell[1] instanceof Array) ? cell[1] : [cell[1]]) : [""]}
                                                                            style={style(taskListUserSettings.settings.get(cell[0]))}
                                                                            id={idx}
                                                                            onClick={() => toggleDescription(index)}
                                                                            classes={item.classes[cell[0]] ? item.classes[cell[0]] : ""}
                                                                            join={cell[0] === "responsible"}
                                                                        />
                                                                }
                                                            </Fragment>
                                                        )
                                                    });
                                            })}
                                            {/* Buttons */}
                                            <div style={{width: "30px"}} className="center-vertically">
                                                <div>
                                                    <Button className="but but-primary but-sm"
                                                            onClick={() => handleEditTask(item.task)}
                                                            disabled={!selectedTaskList}
                                                    >
                                                        <i className="fa fa-pencil-square-o"
                                                           style={{display: "flex"}}></i>
                                                    </Button>
                                                </div>
                                            </div>

                                        </div>

                                        <motion.div
                                            {...ShrinkAnimation}
                                            className="table-row overflow-y-hidden"
                                        >
                                            <CollapsibleRow2
                                                index={index}
                                                showIndex={showDescription}
                                                // onClick={() => showDescription === -1 ? toggleDescription(index) : toggleDescription(-1)}
                                                className={"w-100"}
                                                textClassName=""
                                                // textClassName="pt-2 pb-2"
                                                showAll={showAllDescriptions}
                                            >
                                                {item.task.description}
                                            </CollapsibleRow2>
                                        </motion.div>
                                    </Fragment>
                                )
                            }) : null}
                    </AnimatePresence>
                    {/*Footer*/}
                    <div className="table-footer" style={{minWidth: tableWidth ? tableWidth : "0"}}>
                        {[...taskListUserSettings.settings.values()].map((item, index) => {
                            return item.visible ? (
                                <div key={index}
                                    // className={"column-visible" + (!item.visible ? " column-hidden" : "")}
                                ></div>
                            ) : null;
                        })}
                    </div>
                </div>
            </div>
            {/*<Button className="but but-sm" onClick={() => console.log(tableWidth)}>*/}
            {/*    User settings*/}
            {/*</Button>*/}

            {createPortal(
                <TaskAddEditComp
                    showComponent={showAddTask}
                    editHandler={handleTaskAdd}
                    cancelHandler={cancelAddEdit}
                    task={newTask}
                    setTask={setNewTask}
                    // taskLists={taskLists}
                    editMode={editMode}
                    deleteHandler={setTaskToDelete}
                    selectedTaskList={selectedTaskList!}
                />,
                document.getElementById('root') as HTMLElement)
            }

            {createPortal(
                <ConfirmationWindow
                    header={"Confirmation"}
                    body={`Do you really want to delete task "${tasks.find(item => item.id === taskToDelete)?.name}".`}
                    show={taskToDelete > -1}
                    onConfirm={deleteTask}
                    onCancel={() => setTaskToDelete(-1)}
                />,
                document.getElementById('root') as HTMLElement)
            }

        </div>
    )
}


class TasksTableProps {
    taskListUserSettings: TaskListUserSettings;
    showAllDescriptions: boolean;
    selectedTaskList: TaskList | undefined | null;
    taskListFilter: Map<string, string | null> | null;
    customFilter: Map<string, string>;
    externalGetTasks: boolean;
    externalAddNewTask: boolean;
    userSettings: UserSettings;
}

export const generateHash = (key: string): number => {
    let hash = 0;
    [...key].forEach((c) => {
        hash += c.charCodeAt(0);
    })
    return hash;
}

export class Sorter {
    value: string;
    ascending: boolean;
}