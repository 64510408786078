import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../forms/Button";
import * as Yup from "yup";
import {AnimatePresence, motion} from "framer-motion";
import {ErrorMessage} from "../SignUpComp";
import {passwordConfirmSchema, passwordSchema, usernameSchema, yupErrorList} from "../../services/ValidationService";
import {useHttp} from "../../hooks/http.hook";
import {beurl} from "../../constants/Constants";
import {OpacityAnimation} from "../TaskComp/TaskAnimations";

export const ChangeAccountToLocal = (props: ChangeAccountTypeProps) => {

    /* Local constants */
    const request = useHttp();
    const url = beurl(window.location.host.split(':')[0]) + "api/current/";

    /* Local constants*/
    const {onSuccess, onCancel} = props;

    /* Local Refs */
    const startRef = useRef<HTMLInputElement>(null);

    /* Local states */
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [userName, setUserName] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (startRef && startRef.current) {
            // setTimeout(() => {
            startRef.current!.focus();
            // }, 200)
        }
    }, []);

    useEffect(() => {
        setMessage("");
    }, [userName]);

    /* Web requests */
    const changeAccountType = () => {
        request(url + "changetolocal", "POST", JSON.stringify({
            name: userName,
            password: password1,
            email: ""
        }))
            .then(() => {
                onSuccess();
            })
            .catch((e) => {
                console.log(e.message);
                setMessage(e.message);
            })
    }

    /* Key handlers */
    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            handleSubmit();
        }
        if (key === "Escape") {
            onCancel();
        }
    }

    /* Validation */
    const schema = Yup.object().shape({
        username: usernameSchema,
        password: passwordSchema,
        confirmPassword: passwordConfirmSchema
    });

    const handleSubmit = () => {
        schema.validate({username: userName, password: password1, confirmPassword: password2}, {abortEarly: false})
            .then(() => {
                changeAccountType();
                setErrors({});
                // console.log("Validation succeeded. ");
                // console.log(data);
            })
            .catch((err) => {
                if (err instanceof Yup.ValidationError) {
                    setErrors(yupErrorList(err));
                }
            })
    }

    return (
        <div className="modalwindow">
            <div className="modalcontent">
                <div className="modalheader">
                    <h3 className="modaltitle center-horizontally heading-font1">
                        Change account type to local
                    </h3>
                </div>
                <div className="center-horizontally">
                    Enter new local credentials
                </div>
                <div className="mt-4">
                    <InputSelectField value={userName} handleAction={(value: string) => setUserName(value)}
                                      type={InputSelectType.input}
                                      label="User Name" backgroundcolor="#fafafa"
                                      handleKeyAction={handleKeyUp}
                                      ref={startRef}
                                      error={errors.hasOwnProperty("username")}
                    />
                </div>
                <AnimatePresence>
                    {errors.hasOwnProperty("username") &&
                        <ErrorMessage message={errors["username"]}/>
                    }
                </AnimatePresence>
                <div className="mt-4">
                    <InputSelectField value={password1} handleAction={setPassword1}
                                      type={showPassword ? InputSelectType.input : InputSelectType.password}
                                      label="Password" backgroundcolor="#fafafa"
                                      handleKeyAction={handleKeyUp}
                                      error={errors.hasOwnProperty("password")}
                    />
                </div>
                <AnimatePresence>
                    {errors.hasOwnProperty("password") &&
                        <ErrorMessage message={errors["password"]}/>
                    }
                </AnimatePresence>
                <div className="mt-4">
                    <InputSelectField value={password2} handleAction={setPassword2}
                                      type={showPassword ? InputSelectType.input : InputSelectType.password}
                                      label="Password confirmation" backgroundcolor="#fafafa"
                                      handleKeyAction={handleKeyUp}
                                      error={errors.hasOwnProperty("confirmPassword")}
                    />
                </div>
                <AnimatePresence>
                    {errors.hasOwnProperty("confirmPassword") &&
                        <ErrorMessage message={errors["confirmPassword"]}/>
                    }
                </AnimatePresence>

                <div className="center-all mt-3">
                    <InputSelectField value={showPassword} handleAction={setShowPassword}
                                      type={InputSelectType.checkbox}
                                      label="- show password"
                                      handleKeyAction={handleKeyUp}
                    />
                </div>
                <div className="" style={{height: "1.5rem"}}>
                    <AnimatePresence>
                        {message !== "" &&
                            <motion.div
                                {...OpacityAnimation}
                                className="center-horizontally error-text overflow-hidden"
                            >
                                {message}
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>

                <div className="d-flex center-all mt-1 gap-2">
                    <div>
                        <Button className="but but-primary but-sm"
                                onClick={handleSubmit}>Save password
                        </Button>
                    </div>
                    <div>
                        <Button className="but but-warning but-sm" onClick={() => onCancel()}>Cancel
                        </Button>
                    </div>
                </div>

            </div>
        </div>

    )
}

class ChangeAccountTypeProps {
    onSuccess: Function;
    onCancel: Function;
}