import {useHttp} from "../hooks/http.hook";
import {beurl} from "../constants/Constants";
import {useEffect, useRef, useState} from "react";
import {Announcement} from "../model/Announcement";
import dayjs from "dayjs";
import ConfirmationWindow from "../forms/ConfirmationWindow";
import {PageHeader} from "./PageHeader";
import {Button} from "../forms/Button";
import {SimpleCell} from "../forms/SimpleCell";
import {InputSelectField, InputSelectType} from "../forms/InputSelectField";
import {logout} from "../services/UserService";
import {useNavigate} from "react-router-dom";

export const DashboardComp = () => {

    /* Local constants */
    const request = useHttp();
    const announceUrl = beurl(window.location.host.split(':')[0]) + "api/announcement";

    /* Local states */
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const [announcementToDelete, setAnnouncementToDelete] = useState(-1)
    const [pageable, setPageable] = useState<Pageable>(new Pageable());
    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState<Set<number>>(new Set());
    const [selectedDelete, setSelectedDelete] = useState(false);
    const [allDelete, setAllDelete] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const allRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    /* Hooks */
    useEffect(() => {
        // getAnnouncements();
        getPagedAnnouncements();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, [])

    useEffect(() => {

        if (selected.size > 0 && selected.size !== announcements.length && allRef && allRef.current) {
            allRef.current.indeterminate = true;
        } else if (selected.size === 0 && allRef && allRef.current) {
            allRef.current.indeterminate = false;
            setSelectAll(false);
        } else if (selected.size === announcements.length) {
            setSelectAll(true);
            if (allRef && allRef.current) {
                allRef.current.indeterminate = false;
            }
        }

    }, [selected]);

    useEffect(() => {
        getPagedAnnouncements();
        // console.log(pageable);
    }, [pageable.page]);

    useEffect(() => {
        if (allRef && allRef.current) {
            allRef.current.indeterminate = false;
        }
        setAllDelete(false);
        setSelected(new Set());

    }, [announcements]);

    /* Functions */

    const getPagedAnnouncements = () => {
        request(announceUrl + "/pagedannouncements?page=" + pageable.page + "&size=" + pageable.pageSize +
            "&sort=createdAt,desc")
            .then(data => {
                // console.log(data);
                setAnnouncements(data.content);
                setPageable({...pageable, totalPages: Math.max(data.totalPages, 1), totalElements: data.totalElements});
            })
            .catch((e) => {
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })

    }

    const acknowledge = (id: number, accept: boolean) => {
        request(announceUrl + "/acknowledge/" + id + "/" + accept, "POST")
            .catch((e) => {
                console.log("Failed to acknowledge request.");
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
            .finally(() => {
                getPagedAnnouncements();
            })
    }

    const deleteAnnouncement = () => {
        request(announceUrl + "/announcement/" + announcements[announcementToDelete].id, "DELETE")
            .then(() => setAnnouncementToDelete(-1))
            .catch((e) => {
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
            .finally(getPagedAnnouncements)
    }

    const deleteSelected = () => {
        request(announceUrl + "/selectedannouncements",
            "DELETE", JSON.stringify([...selected]))
            .then(() => setSelectedDelete(false))
            .catch((e) => {
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
           })
            .finally(getPagedAnnouncements)
    }

    const deleteAll = () => {
        request(announceUrl + "/allannouncements", "DELETE")
            .then(() => setAllDelete(false))
            .catch((e) => {
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
            .finally(getPagedAnnouncements)
    }

    /* Handlers */
    const handleSelected = (id: number, select: boolean) => {
        const localSelected = new Set(selected);

        if (select) {
            localSelected.add(id);
        } else {
            localSelected.delete(id);
        }
        setSelected(localSelected);
    }

    const handleSelectAll = (select: boolean) => {
        const localSelected: Set<number> = new Set();
        setSelectAll(select);
        if (select) {
            announcements.forEach((item) => {
                localSelected.add(item.id);
            })
        }
        setSelected(localSelected);
    }

    const handlePageable = (param: string, value: number) => {
        const localPageable = {...pageable};
        localPageable[param] = value;
        setPageable(localPageable);
    }


    /* Rendering */
    return (
        <div className="fade-in">
            <PageHeader
                title={"Dashboard"}
                buttonUsed={false}
                refreshUsed={true}
                onRefresh={getPagedAnnouncements}
            />
            <div className="table-container text-start">

                <div className="center-vertically pb-2 shrink-container">
                    <div className="d-flex gap-2 m-auto">
                        <Button className="but but-sm but-light" onClick={() => setAllDelete(true)}>
                            {/*Delete selected*/}
                            <div className="d-flex gap-1">
                                <i className="fa fa-circle-o"/>
                                <i className="fa fa-trash-o"/>
                            </div>
                        </Button>
                        <Button className="but but-sm but-light" onClick={() => setSelectedDelete(true)}>
                            <div className="d-flex gap-1">
                                <i className="fa fa-check"/>
                                <i className="fa fa-trash-o"/>
                            </div>
                        </Button>
                    </div>
                    <div className="heading-font1 m-auto">
                        Announcements
                    </div>
                    <div className="d-flex gap-2 center-vertically m-auto">
                        <Button
                            className="btn btn-sm btn-link"
                            onClick={() => handlePageable("page", Math.max(pageable.page - 1, 0))}
                        >
                            Prev
                        </Button>
                        <div className="">
                            {(pageable.page + 1) + "/" + (pageable.totalPages)}
                        </div>
                        <Button
                            className="btn btn-sm btn-link"
                            onClick={() => handlePageable("page", Math.max(Math.min(pageable.page + 1, pageable.totalPages - 1),0))}
                        >
                            Next
                        </Button>
                    </div>
                </div>

                <div className="collapsible-x-scroll"
                     style={windowWidth > 900 ? {fontSize: "1rem"} : windowWidth < 300 ? {fontSize: "0.5rem"}
                    : {fontSize: (((windowWidth - 600) / 600) * 0.3 + 0.7) + "rem"}}>
                    <div className="table-header announcements">
                        <SimpleCell style={{minWidth: "20px", width: "5%"}} className={"ms-2"}>#</SimpleCell>
                        <SimpleCell style={{minWidth: "25px", width: "5%"}} className={"ms-2"}>
                            <InputSelectField
                                value={selectAll}
                                handleAction={(value: boolean) => handleSelectAll(value)}
                                type={InputSelectType.checkbox}
                                ref={allRef}
                            />
                        </SimpleCell>
                        <SimpleCell style={{minWidth: "70px", width: "20%"}}>Time</SimpleCell>
                        <SimpleCell style={{minWidth: "50px", width: "60%"}}>Message</SimpleCell>
                        <SimpleCell style={{minWidth: "70px", width: "15%"}}>
                            <div></div>
                        </SimpleCell>
                    </div>
                    {announcements
                        .sort((a, b) => {
                            return (a.createdAt > b.createdAt ? -1 : (a.createdAt === b.createdAt) ? 0 : 1);
                        })
                        .map((item, index) => {
                            return (
                                <div className="table-row table-row-bottom-line announcements"
                                     style={item.statusNew ? {fontWeight: "bold"} : {}}
                                     key={item.id}>
                                    <SimpleCell className={"ms-2"} style={{minWidth: "20px", width: "5%"}}
                                    >{(index + 1 + pageable.page * pageable.pageSize) + ""}</SimpleCell>

                                    <SimpleCell style={{minWidth: "25px", width: "5%"}} className={"ms-2"}>
                                        <InputSelectField
                                            value={selected.has(item.id)}
                                            handleAction={(value: boolean) => handleSelected(item.id, value)}
                                            type={InputSelectType.checkbox}
                                        />
                                    </SimpleCell>

                                    <SimpleCell style={{minWidth: "70px", width: "20%"}} className={"monospace"}
                                    >{dayjs(item.createdAt).format("DD-MM-YYYY, HH:mm:ss")}</SimpleCell>
                                    <SimpleCell style={{minWidth: "50px", width: "60%"}}>{item.text}</SimpleCell>

                                    <div style={{minWidth: "fit-content", width: "15%"}}
                                         className="justify-content-end d-flex center-vertically">
                                        {item.requiresAck ?
                                            <div className="d-flex gap-1 me-2">
                                                <Button className="but but-sm but-success"
                                                        onClick={() => acknowledge(item.id, true)}>
                                                    <i className="fa fa-check"></i>
                                                </Button>
                                                <Button className="but but-sm but-danger"
                                                        onClick={() => acknowledge(item.id, false)}>
                                                    <i className="fa fa-close"></i>
                                                </Button>
                                            </div>
                                            :
                                            <div className="me-2">
                                                {/*<Button className="but but-warning but-sm"*/}
                                                {/*        onClick={() => {*/}
                                                {/*            setAnnouncementToDelete(index)*/}
                                                {/*        }}>*/}
                                                {/*    <i className="fa fa-trash-o"></i>*/}
                                                {/*</Button>*/}
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                </div>
                <div className="table-footer announcements"></div>
            </div>
            <ConfirmationWindow
                header={"Confirmation"}
                body={`Do you really want to delete message ${announcementToDelete + 1}?`}
                show={announcementToDelete > -1}
                onConfirm={deleteAnnouncement}
                onCancel={() => setAnnouncementToDelete(-1)}
            />
            <ConfirmationWindow
                header={"Confirmation"}
                body={`Do you really want to delete selected messages?`}
                show={selectedDelete}
                onConfirm={deleteSelected}
                onCancel={() => setSelectedDelete(false)}
            />
            <ConfirmationWindow
                header={"Confirmation"}
                body={`Do you really want to delete all messages on all pages?`}
                show={allDelete}
                onConfirm={deleteAll}
                onCancel={() => setAllDelete(false)}
            />
        </div>
    )
}

class Pageable {
    [key: string]: number;

    page: number = 0;
    pageSize: number = 10;
    totalPages: number = 0;
    totalElements: number = 0;
}