import {createSlice, Slice} from "@reduxjs/toolkit";
import {UserSettings} from "../model/UserSettings";
import {UserBasic} from "../model/UserBasic";
import {Sorter} from "../components/TaskComp/TasksTableComp";

export interface AuthUser {
    user: UserBasic | null;
}

// export interface RootUser {
//     username: {username: string};
// }

const stateInit: AuthUser = {
    // username: null,
    user: null

}

export const userSlice: Slice = createSlice({
    name: 'userState',
    initialState: stateInit,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        removeUser: (state) => {
            state.user = null;
        }
    }
})

/* Task filter reducer */
export interface TaskFilter {
    taskListFilter: Map<string, string | null>;
}

// export interface TaskFilterUnit {
//     key: string,
//     value: string | null;
// }

const filterStateInit: TaskFilter = {
    taskListFilter: new Map()
};

export const taskFilterSlice: Slice = createSlice({
    name: "taskFilterState",
    initialState: filterStateInit,
    reducers: {
        addTaskFilter: (state, action) => {
            state.taskListFilter.set(action.payload.key, action.payload.value);
        },
        removeTaskFilter: (state, action) => {
            state.taskListFilter.delete(action.payload);
        },
        setTaskFilter: (state, action) => {
            // console.log(action.payload);
            state.taskListFilter = action.payload;
            // console.log(current(state));
        },
        resetTaskFilter: (state, action) => {
            state.taskListFilter.clear();
        }

    }
})


/* User settings reducer */

const userSettingsInit: UserSettings = {
    settings: new Map(),
};

export const userSettingsSlice: Slice = createSlice({
    name: "userSettingsState",
    initialState: userSettingsInit,
    reducers: {
        addSetting: (state, action) => {
            if (state.settings) {
                state.settings.set(action.payload.key, action.payload.value);
            } else {
                state.settings = new Map([[action.payload.key, action.payload.value]]);
            }
            // console.log(current(state));
        },
        removeSetting: (state, action) => {
            if (state.settings) {
                state.settings.delete(action.payload);
            }
        },
        resetSetting: (state, action) => {
            if (state.settings) {
                state.settings.clear();
            }
        },
        setSettings: (state, action) => {
            if (action.payload instanceof Map) {
                state.settings = action.payload;
            }
        }

    }
})

/* Sorter reducer */

export interface SorterData {
    sorter: Map<string, Sorter>;
}

const sorterInit: SorterData = {
    sorter: new Map()
};

// const sorterInit = new Map<string, Sorter>();

export const sorterSlice: Slice = createSlice({
    name: "sorterState",
    initialState: sorterInit,
    reducers: {
        addSorter: (state, action) => {
            if (state.sorter) {
                state.sorter.set(action.payload.key, action.payload.value);
            } else {
                state = new Map([[action.payload.key, action.payload.value]]);
            }
            // console.log(current(state));
        },
        removeSorter: (state, action) => {
            if (state.sorter) {
                state.sorter.delete(action.payload);
            }
        },
        resetSorters: (state, action) => {
            if (state.sorter) {
                state.sorter.clear();
            }
        },
        setSorters: (state, action) => {
            if (action.payload instanceof Map) {
                state.sorter = action.payload;
            }
        }

    }
})

