import {User} from "./User";
import {UserBasic} from "./UserBasic";

export class TaskList {
    [key: string]: any;
    id: number | null;
    name: string = "";
    invitedUsers: UserBasic[] = [];
    participants: UserBasic[] = [];
    owner: UserBasic | null = null;
    createdAt: string = "";
    lastModifiedAt: string = "";
    customFields: CustomField[] = [];
    listParameters: ListParameter[] = [];
}

export class CustomField {
    [key: string]: any;
    name: string;
    type: CustomFieldType | null;
    list: string | null;
    filter: boolean;
}

export enum CustomFieldType {
    value = 'value',
    list = 'list',
    // date = 'date'
}

export class ListParameter {
    [key: string]: any;
    name: string = "";
    list: string[] = [];
}
