import {useJSON} from "../../hooks/json.hook";
import {Task} from "../../model/Task";


export const setOverflowVisibility = (e: TransitionEvent, field: HTMLElement) => {
    if (e.propertyName === 'max-height' && field) {
        if (field.clientHeight > 0) {
            field.style.overflowY = 'visible';
        }
    }
}

export const prepareFiltersUrl = (defUrl: string, taskListFilter: Map<string, string | null> | null, customFilter: Map<string, string>): string => {
    if ((taskListFilter && taskListFilter.size > 0) || (customFilter && customFilter.size > 0)) {
        let urlWithItems = defUrl + '?';

        if (taskListFilter && taskListFilter.size > 0) {
            taskListFilter.forEach((value, key) => {
                if (value !== "") {
                    urlWithItems += `${key}=${value}&`;
                }
            })
        }
        if (customFilter && customFilter.size > 0) {
            [...customFilter.values()].forEach((value, index) => {
                urlWithItems += `customFilter${index}=${value}&`
            })
        }
        return urlWithItems.slice(0, -1);
    } else {
        return defUrl;
    }
}

export const applyLocalFilteringForCustomFields = (data: any, customFilter: Map<string,string>): Task[] => {

    const initRawTasks: any[] = [...data];
    initRawTasks.forEach(item => {
        if (item.customFields && item.customFields !== "") {
            item.customFields = JSON.parse(item.customFields, useJSON().mapFromJSON)
        } else {
            item.customFields = new Map<string, string>();
        }
    });
    /* Remove empty entries from the custom filters Map */
    [...customFilter.entries()].forEach(item => {
        if (item[1] === "") {
            customFilter.delete(item[0]);
        }
    })
    /* Local filter for custom fields */
    const rawTasks = initRawTasks.filter(item => {
        let pass = true;
        customFilter.forEach((value, key) => {
            if (!(item.customFields.has(key) && item.customFields.get(key).toLowerCase().includes(value.toLowerCase()))) {
                pass = false;
            }
        })
        return pass;
    })

    return rawTasks;

}

